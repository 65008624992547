/*
 userUrl: "https://mist-cal-api.kitdev.no/api/v1/user/",
adminUrl: "https://mist-cal-api.kitdev.no/api/v1/admin/",
exportUrls: {
  rss: "https://mist-cal-api.kitdev.no/rss/",
  ics: "https://mist-cal-api.kitdev.no/ics/"
},
*/

/*
   userUrl: "http://localhost:5050/api/v1/user/",
   adminUrl: "http://localhost:5050/api/v1/admin/",
   exportUrls: {
	rss: "http://localhost:5050/rss/",
  	ics: "http://localhost:5050/ics/"
  },

 */

let config = {
  userUrl: "https://mist-cal-api.kitdev.no/api/v1/user/",
  adminUrl: "https://mist-cal-api.kitdev.no/api/v1/admin/",
  exportUrls: {
    rss: "https://mist-cal-api.kitdev.no/rss/",
    ics: "https://mist-cal-api.kitdev.no/ics/"
  },
  fieldIcons: {
    microphone: '<i class="fas fa-microphone"></i>',
    music: '<i class="fas fa-music"></i>',
    knifefork: '<i class="fas fa-utensils"></i>',
    beer: '<i class="fas fa-beer"></i>',
    cocktail: '<i class="fas fa-glass-martini-alt"></i>',
    coffee: '<i class="fas fa-coffee"></i>',
    wheelchair: '<i class="fab fa-accessible-icon"></i>',
    laptop: '<i class="fas fa-laptop"></i>',
    bullhorn: '<i class="fas fa-bullhorn"></i>',
    children: '<i class="fas fa-child"></i>'
  },
  fullAccessUser: "2cb3a533-cf61-4e04-a0cf-7362a313a7a8",
  confirmationSender: "post@mist.no",
  confirmationText: `Hei {{custom.kontaktperson}},

  Vi bekrefter å ha mottatt forespørsel om arrangement. Vennligst bekreft at opplysningene er riktige ved å svare på denne eposten.
  
  --
  Arrangement: {{title}}
  Starttidspunkt: {{start_date}} {{start_time}}
  Slutttidspunkt: {{end_date}} {{end_time}}
  Antall personer: {{custom.besøkstall}}
  Pris: {{custom.pris}}
  
  Kontaktperson: {{custom.kontaktperson}}
  Epost: {{custom.epost}}
  Telefon: {{custom.telefon}}
  Fakturaadresse: {{custom.fakturaadresse}}
  
  Generell informasjon:
  Kategori: {{category}}
  {{description}}
  --
  
  Avbestilling senere enn en uke før omvisning medfører at totalsummen likevel må betales. Endring i antall deltakere meddeles skriftlig senest 2 dager før. Bestilt omvisning faktureres for minimum 15 personer.
  
  For grunnskole/barnehage: Lærere må betale 140,- pr pers. For VGS/folkehøgskole: Lærere går gratis.
  
  Ved avbestilling av arrangement på scene, skal avbestilling skje minst en måned før arrangement. Ved senere avlysning tilkommer en avbestillingsavgift på 10% av salleie. Ved leie av teknisk utstyr skal utstyr leveres tilbake i samme stand. Ved skader skal leietager belastes kostnader. 
  
  Fra 01.07.2010 ble museene innlemmet i mva reglene. MiST er frivillig registrert i mva registeret for utleie fast eiendom. Dvs. MiST beregner alltid mva på fakturaer ang utleie sal og scene, utstyr og personell, samt billetter/inngang til museet. 
  Oppgitte priser i tilbudet er inklusive mva. 
  
  
  Velkommen til oss!
  
  Med vennlig hilsen
  Museene i Sør-Trøndelag AS
  Sentralbord: 73 60 41 00 / Epost: post@mist.no
  http://www.mist.no/`
};

// Coyo User ID used during development/testing
// 2cb3a533-cf61-4e04-a0cf-7362a313a7a8

export default Object.freeze(Object.assign({}, config));
