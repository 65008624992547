import React from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import ClipboardIcon from "@material-ui/icons/Assignment";

import { withStyles } from "@material-ui/core";

import AdminUtility from "../../../utility";

const styles = (theme) => ({
  root: {
    color: theme.palette.text.primary,
  },
  icon: {
    margin: theme.spacing.unit,
  },
  button: {
    margin: theme.spacing.unit,
  },
  clipboardIcon: {
    margin: theme.spacing.unit,
    float: "right",
  },
});

/**
 * Component used to display the dialog
 * with the calendar's embed URL.
 */
class EmbedDialog extends React.Component {
  constructor() {
    super();

    this.state = {
      calendarId: "",
    };
  }

  componentDidMount() {
    if (
      typeof this.props.calendarId !== "undefined" &&
      this.state.calendarId === ""
    ) {
      this.setState({
        calendarId: this.props.calendarId,
      });
    }
  }

  cancelDialog = () => {
    this.props.onCancel();
  };

  createEmbedUrl = () => {
    return window.location.origin + "/calendar/" + this.state.calendarId;
  };

  copyToClipboard = () => {
    let el = document.getElementById("embed-url");
    if (typeof el !== "undefined" && el !== null) {
      el.select();
      document.execCommand("copy");
    }
  };

  render() {
    const { classes, open, calendarId, ...other } = this.props;
    let embedCode =
      '<a class="mistcalendar" href="' +
      this.createEmbedUrl() +
      '">Kalender</a><script src=https://mist-cal.kitdev.no/cal.js"></script>';

    return (
      <Dialog
        open={open || false}
        onCancel={this.cancelDialog}
        disableBackdropClick
        aria-labelledby="embed-dialog-title"
        {...other}
      >
        <DialogTitle id="embed-dialog-title">
          {"Embedkode"}
          <Tooltip title="Kopier til utklippstavlen">
            <IconButton color="primary" onClick={this.copyToClipboard}>
              <ClipboardIcon className={classes.clipboardIcon} />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent>
          <textarea
            id="embed-url"
            readOnly={true}
            rows="3"
            style={{ resize: "none", width: "500px", height: "120px" }}
            defaultValue={embedCode}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            className={classes.button}
            onClick={this.cancelDialog}
          >
            Lukk
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

EmbedDialog.propTypes = {
  calendarId: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EmbedDialog);
