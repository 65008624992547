import React from "react";

import Utility from "../../utility";
import PreviewPopUp from "../../dialogs/previewpopup";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  event: {
    display: "block",
    fontSize: "13px",
    borderRadius: "4px",
    padding: "5px 7px",
    marginBottom: "5px",
    lineHeight: "14px",
    background: "#54B78e",
    color: "white",
    textDecoration: "none",
    cursor: "pointer",
    margin: "5px",
    border: "1px solid #fff"
  },
  eventMultiStart: {
    display: "block",
    fontSize: "13px",
    borderRadius: "4px 0 0 4px !important",
    padding: "5px 7px",
    marginBottom: "5px",
    lineHeight: "14px",
    background: "#54B78e",
    color: "white",
    textDecoration: "none",
    cursor: "pointer",
    margin: "5px 0 5px 5px",
    border: "1px solid #fff"
  },
  eventMultiEnd: {
    display: "block",
    fontSize: "13px",
    borderRadius: "0 4px 4px 0px !important",
    padding: "5px 7px",
    marginBottom: "5px",
    lineHeight: "14px",
    background: "#54B78e",
    color: "white",
    textDecoration: "none",
    cursor: "pointer",
    margin: "5px 5px 5px 0",
    border: "1px solid #fff"
  },
  eventMultiOngoing: {
    display: "block",
    fontSize: "13px",
    padding: "5px 7px",
    marginBottom: "5px",
    lineHeight: "14px",
    background: "#54B78e",
    color: "white",
    textDecoration: "none",
    cursor: "pointer",
    margin: "5px 0 5px 0",
    border: "1px solid #fff"
  },
  eventTitle: {
    color: "#000",
    marginTop: 3,
    marginRight: 0,
    marginBottom: 7
  },
  eventTime: {
    color: "rgba(0, 0, 0, .5)"
  }
});

/**
 * Component used when rendering an event in the calendar.
 */
class EventRenderer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      events: [],
      eventCategories: [],
      fieldGroups: [],
      anchorEl: null,
      selectedEvent: null,
      cellDate: null,
      showDeleteDialog: false,
      user: Utility.getUserCookie()
    };
  }

  componentDidMount() {
    if (typeof this.props.events !== "undefined" && typeof this.props.categories !== "undefined") {
      this.setState({
        events: this.props.events,
        categories: this.props.categories,
        cellDate: this.props.date
      });
    }
  }

  render() {
    const { anchorEl } = this.state;
    const { classes } = this.props;

    if (this.state.events !== null) {
      let events = this.state.events,
        multiDay = events.filter(e => {
          return e.multiDay === true;
        }),
        singleDay = events.filter(e => {
          return e.multiDay !== true;
        });

      // Multiday events has priority above
      // singel day events.

      let rendered = [].concat(multiDay, singleDay);

      return rendered.map(e => {
        return this._renderEvent(e, classes, anchorEl);
      });
    } else {
      return "";
    }
  }

  _renderEvent = (data, classes, anchorEl) => {
    let categoryColor = this._getCategoryColor(data);
    return this._renderContent(data, categoryColor, data.event_start, data.event_end, classes, anchorEl);
  };

  _renderContent = (evt, categoryColor, starts, ends, classes) => {
    let cellDate = this.state.cellDate,
      eventClassName = classes.event,
      evtMultiState = null,
      startDate = Utility.convertFromDbDateToDate(starts),
      endDate = Utility.convertFromDbDateToDate(ends),
      isoCellDate = Utility.dateToISO8601(cellDate),
      startIso = Utility.dateToISO8601(startDate),
      endIso = Utility.dateToISO8601(endDate);

    if (evt.multiDay) {
      if (startIso === isoCellDate) {
        // Event starts today
        eventClassName = classes.eventMultiStart;
        evtMultiState = "starts";
      } else if (endIso === isoCellDate) {
        // Event ends today
        eventClassName = classes.eventMultiEnd;
        evtMultiState = "ends";
      } else {
        // Ongoing event
        eventClassName = classes.eventMultiOngoing;
        evtMultiState = "ongoing";
      }
    }

    let eventStatus = Utility.getEventStatusFromFields(evt.fields);
    eventClassName += " event " + eventStatus;
    if (eventStatus === "cancelled") {
      categoryColor = "";
    }

    if (startIso === isoCellDate || endIso === isoCellDate || (startIso < isoCellDate && endIso > isoCellDate)) {
      return (
        <div style={this._getContentStyle(evtMultiState)} key={"wrap" + evt.id}>
          <div
            key={evt.id}
            data-id={evt.id}
            className={eventClassName}
            style={{
              backgroundColor: categoryColor,
              borderColor: categoryColor
            }}
            onMouseOver={this._showPreview.bind(this, evt)}
            onMouseOut={this._closePreview.bind(this, evt)}
            onClick={this.props.loadEventHandler.bind(this, evt.id)}>
            <div>
              <div className={classes.eventTitle}>
                <span className="title">
                  {evt.title}
                  <span
                    className="icon"
                    dangerouslySetInnerHTML={{
                      __html: Utility.getIconsFromFields(evt.fields)
                    }}
                  />
                </span>
              </div>
              {this._renderTime(classes, starts, ends)}
            </div>
          </div>
          <PreviewPopUp selectedevent={this.state.selectedEvent} anchorel={this.state.anchorEl} categories={this.props.categories} />
        </div>
      );
    } else {
      return null;
    }
  };

  /**
   * Renders the event time information.
   */
  _renderTime(classes, starts, ends) {
    let result = null;

    let startD = Utility.convertFromDbDateToDate(starts),
      endD = Utility.convertFromDbDateToDate(ends),
      startTime = Utility.convertEventDateTimeToTime(starts),
      endTime = Utility.convertEventDateTimeToTime(ends),
      cellDate = this.state.cellDate;

    if (startD.getDate() === cellDate.getDate() && endD.getDate() === cellDate.getDate()) {
      result = (
        <div className={classes.eventTime}>
          {startTime} - {endTime}
        </div>
      );
    } else if (startD.getDate() === cellDate.getDate() && endD.getDate() > cellDate.getDate()) {
      result = <div className={classes.eventTime}>{startTime} - </div>;
    } else if (startD.getDate() < cellDate.getDate() && endD.getDate() === cellDate.getDate()) {
      result = <div className={classes.eventTime}> - {endTime}</div>;
    } else {
      result = <div>&nbsp;</div>;
    }

    return result;
  }

  _closePreview = () => {
    this.setState({
      anchorEl: null,
      selectedEvent: null
    });
  };

  _showPreview = (item, event) => {
    this.setState({
      anchorEl: event.currentTarget,
      selectedEvent: item
    });
  };

  _getContentStyle(state) {
    switch (state) {
      case "starts":
        return { marginRight: -1 };
      case "ongoing":
        return { marginLeft: -1, marginRight: -1 };
      case "ends":
        return { marginLeft: -1 };
      default:
        return {};
    }
  }

  _getCategoryColor(evt) {
    let categoryColor = "#aaaaaa";
    if (typeof evt.event_category_id !== "undefined" && evt.event_category_id !== null) {
      let color = Utility.getCategoryColorFromId(evt.event_category_id, this.state.categories);
      if (color !== null) {
        categoryColor = color;
      }
    }
    return categoryColor;
  }
}

EventRenderer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(EventRenderer);
