import React from "react";

import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import Utility from "../utility";
import EditCalendarDialog from "./dialogs/editcalendardialog";
import CalendarListItem from "./calendarlistitem";

const styles = theme => ({
  root: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: theme.spacing.unit * 3
  }),
  addbtn: {
    marginTop: 6
  }
});

let cookie = Utility.getUserCookie();

class CalendarList extends React.Component {
  /**
   * Object constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);

    this.state = {
      calendars: [],
      showEditDialog: false
    };
  }

  /**
   * Displays the add calendar dialog.
   */
  showEditDialog = () => {
    this.setState({ showEditDialog: true });
  };

  /**
   * Cancels the add dialog.
   */
  cancelEditDialog = () => {
    this.setState({ showEditDialog: false });
  };

  /**
   * Saves a new calendar item.
   * @param {object} item
   */
  saveHandler(item) {
    Utility.apiRequest(
      "calendar",
      "POST",
      function(data) {
        if (data && typeof data.id !== "undefined") {
          window.location.replace("/admin?u=" + cookie.uid);
        }
      },
      item
    );
  }

  /**
   * Saves the updated version of the specified calendar item.
   * @param {object} item
   */
  updateHandler(item) {
    Utility.apiRequest(
      "calendar/" + item.id,
      "PUT",
      function(data) {
        if (data.id === item.id) {
          window.location.replace("/admin?u=" + cookie.uid);
        }
      },
      item
    );
  }
  /**
   * Deletes a calendar.
   * @param {int} itemId
   */
  deleteHandler(itemId) {
    let self = this;
    Utility.apiRequest("calendar/" + itemId, "DELETE", function(data) {
      if (data.calendarId === itemId) {
        // Remove the item from the list of calendars.
        var calendars = self.state.calendars.filter(item => {
          return item.id !== itemId;
        });

        self.setState({ calendars: calendars });
      }
    });
  }

  /**
   * Renders the table containing the calendars.
   */
  render() {
    const { classes } = this.props;
    return (
      <div>
        <AppBar position="static" color="primary" elevation={0}>
          <Toolbar>
            <Typography variant="title" color="inherit" style={{ flexGrow: 1 }}>
              Kalenderadministrasjon
            </Typography>
            <div className="rightNav">
              <Button color="default" variant="contained" className={classes.addbtn} onClick={e => this.showEditDialog(e)}>
                <AddIcon /> Legg til kalender
              </Button>
            </div>
          </Toolbar>
        </AppBar>
        <Paper className={classes.root} elevation={0}>
          <div className={classes.calendarList}>
            <Grid container direction="row" justify="flex-start" alignItems="stretch">
              {this.state.calendars.map(x => {
                if (!x.deleted) {
                  return <CalendarListItem data={x} key={x.id} onDeleteItemHandler={e => this.deleteHandler(e)} onUpdateItemHandler={e => this.updateHandler(e)} />;
                }
                return null;
              })}
            </Grid>
          </div>
        </Paper>
        <EditCalendarDialog open={this.state.showEditDialog} item={{}} onCancel={this.cancelEditDialog} updateItemHandler={e => this.saveHandler(e)} />
      </div>
    );
  }

  /**
   * Loads the list of calendars.
   */
  loadCalendars() {
    let self = this;
    Utility.apiRequest("calendar", "GET", function(data) {
      self.setState({ calendars: data });
    });
  }

  componentDidMount() {
    this.loadCalendars();
  }
}

export default withStyles(styles)(CalendarList);
