import React from "react";

import { withStyles } from "@material-ui/core/styles";

import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import Utility from "../utility";
import EventItem from "../dialogs/eventitem";
import EventRenderer from "./eventrenderer";
import LeftDrawer from "./leftdrawer";
import ListViewer from "./listview";
import FootNav from "./footnav";

const styles = theme => ({
  root: {
    overflow: "hidden"
  },
  event: {
    display: "block",
    fontSize: "13px",
    borderRadius: "4px",
    padding: "5px 7px",
    marginBottom: "5px",
    lineHeight: "14px",
    background: "#54B78e",
    color: "white",
    textDecoration: "none",
    cursor: "pointer"
  },
  eventTitle: {
    display: "inline",
    marginLeft: "5px",
    color: "#000"
  },
  eventTime: {
    display: "inline",
    color: "#000"
  },
  topBar: {
    position: "fixed",
    backgroundColor: "white",
    width: "100%",
    top: 0,
    left: 0,
    padding: "5px",
    zIndex: 20
  },
  topBarTitle: {
    display: "inline",
    verticalAlign: "middle",
    marginLeft: 15,
    fontSize: "1.5em"
  },
  periodInfo: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "100px"
  }
});

/**
 * Holds the selected date.
 */
let selectedDate = Utility.dateToISO8601(new Date());

/**
 * Class hosting methods used when rendering the calendar view.
 */
class Calendar extends React.Component {
  constructor() {
    super();
    this.eventitem = React.createRef();

    this.state = {
      data: null,
      columns: 7,
      maxTiles: "",
      today: "",
      calStartDate: "",
      showAddEventDialog: false,
      selectedEvent: {},
      events: [],
      selectedPeriod: {},
      tabValue: 0,
      searchQuery: "",
      showSearchResults: false
    };
  }

  componentDidMount() {
    // Hide helperlinks, displayed on the root page, when displaying a specified calendar.
    let helperLinks = document.getElementById("helperlinks");
    if (helperLinks) {
      helperLinks.style.display = "none";
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props !== prevProps) {
      this._initCalendar(this.props.calendar);
    }
    if (this.tabValue === 0) {
      this.setState({ showSearchResults: false });
    }
  }

  /**
   * Fetches data for the selected period and re-renders the calendar.
   */
  onShowCalendar = () => {
    this._initCalendar(null);
    this.closeDrawer();
  };

  /**
   * Loads event data, when the user has clicked on an existing event.
   */
  loadEvent = eventId => {
    let self = this;
    Utility.apiRequest("event/" + eventId, "GET", data => {
      data.startTime = Utility.convertEventDateTimeToTime(data.event_start);
      data.startDate = Utility.convertEventDateTimeToDate(data.event_start);
      data.endTime = Utility.convertEventDateTimeToTime(data.event_end);
      data.endDate = Utility.convertEventDateTimeToDate(data.event_end);
      self.setState({ selectedEvent: data, showAddEventDialog: true });
    });
  };

  /**
   * Saves the event data, or updates the data for the selected event.
   */
  saveEvent = eventData => {
    let self = this,
      state = self.state,
      url = "event",
      method = "POST";

    eventData.calendarId = state.data.id;
    eventData.eventCategoryId = eventData.eventCategoryId === -1 ? null : eventData.eventCategoryId;

    if (typeof eventData.id !== "undefined" && eventData.id !== "") {
      // Update existing event data.
      url = "event/" + eventData.id;
      method = "PUT";
    }

    Utility.apiRequest(
      url,
      method,
      data => {
        self._initCalendar(state.data);
      },
      eventData
    );
  };

  /**
   * Deletes the specified event.
   */
  deleteEvent = eventId => {
    let self = this,
      state = self.state;
    Utility.apiRequest("event/" + eventId, "DELETE", data => {
      self._initCalendar(state.data);
      self._cancelAddEventDialog();

      // Temporary hack. See #115.
      window.location.reload();
    });
  };

  toggleDrawer = () => {
    this.leftdrawer.toggle();
  };

  closeDrawer = () => {
    this.leftdrawer.close();
  };

  handleTabChange = (event, tabValue) => {
    this.setState({ tabValue });
  };

  handleTabClick = event => {
    this.setState({ searchQuery: "", showSearchResults: false });
  };

  /**
   * Renders the complete view.
   */
  render() {
    const { classes } = this.props;
    const { tabValue } = this.state;

    return [
      this._renderTopBar(classes),
      <div className={classes.root}>
        <LeftDrawer onRef={ref => (this.leftdrawer = ref)} showCalendarCallback={this.onShowCalendar} calendarId={this.state.data && this.state.data.id} />
        <Tabs value={tabValue} onChange={this.handleTabChange} onClick={this.handleTabClick} className="tabWrapper main">
          <Tab className={classes.tab + " tab"} label="Månedsvisning" />
          <Tab className={classes.tab + " tab"} label="Listevisning" />
        </Tabs>
        <div id="searchfield">
          <form onSubmit={evt => this._doSearch(evt)}>
            <input type="text" name="q" placeholder="Søk.." key="search" value={this.state.searchQuery} onChange={evt => this._updateSearchQuery(evt)} />
            <input type="hidden" name="u" value={this._getUserId()} />
          </form>
        </div>
        {this.state.data && this.state.data.eventCategories && this._renderCategories()}
        {tabValue === 0 && (
          <div>
            {this.state.data !== null && typeof this.state.data.id !== "undefined" && (
              <div id="calendar">
                {this._renderWeekdays()}
                {this._renderBody()}
                {this.state.calendarRows}
                <EventItem
                  open={this.state.showAddEventDialog}
                  selectedDate={selectedDate}
                  data={this.state.selectedEvent}
                  categories={this.state.data.eventCategories}
                  fieldGroups={this.state.data.fieldGroups}
                  bookingOptions={this.state.data.booking}
                  saveHandler={this.saveEvent}
                  deleteHandler={this.deleteEvent}
                  cancelHandler={e => this._cancelAddEventDialog(e)}
                />
              </div>
            )}
          </div>
        )}{" "}
        {tabValue === 1 && (
          <div>
            {this.state.data !== null && typeof this.state.data.id !== "undefined" && (
              <div>
                <ListViewer
                  periodStart={this._correctJsZeroDate(this.state.selectedPeriod[0])}
                  periodEnd={this._correctJsZeroDate(this.state.selectedPeriod[1])}
                  calendarId={this.state.data && this.state.data.id}
                  eventCategories={this.state.data.eventCategories}
                  loadEventHandler={this.loadEvent}
                  searchQuery={this.state.searchQuery}
                  showSearchResults={this.state.showSearchResults}
                />
                <EventItem
                  open={this.state.showAddEventDialog}
                  onCancel={e => this._cancelAddEventDialog(e)}
                  selectedDate={selectedDate}
                  data={this.state.selectedEvent}
                  categories={this.state.data.eventCategories}
                  fieldGroups={this.state.data.fieldGroups}
                  bookingOptions={this.state.data.booking}
                  saveHandler={this.saveEvent}
                  deleteHandler={this.deleteEvent}
                  cancelHandler={e => this._cancelAddEventDialog(e)}
                />
              </div>
            )}
          </div>
        )}
        <FootNav data={this.state.data} />
      </div>
    ];
  }

  /**
   * Renders the top bar including the title and drawer menu.
   * @param {Object} classes
   */
  _renderTopBar(classes) {
    return (
      <div className={classes.topBar} key={Math.random()}>
        <IconButton onClick={e => this.toggleDrawer(e)} size="small">
          <MenuIcon className={classes.icon} />
        </IconButton>
        <h1 className={classes.topBarTitle}>
          <span className="caltitle">{this.state.data && this.state.data.title}: </span>
          {this._renderSelectedPeriod(classes)}
        </h1>
        <div className="rightNav">
          <IconButton onClick={e => this._stepCalendarMonth(-1)} size="small">
            <ArrowBackIcon className={classes.icon} />
          </IconButton>
          <IconButton onClick={e => this._stepCalendarMonth(1)} size="small">
            <ArrowForwardIcon className={classes.icon} />
          </IconButton>
        </div>
      </div>
    );
  }

  _stepCalendarMonth(step) {
    if (typeof this.state.selectedPeriod === "undefined") {
      return;
    }
    let p = this.state.selectedPeriod,
      month = Utility.getMonthAndYearFromSelectedPeriod(p)[0],
      year = Utility.getMonthAndYearFromSelectedPeriod(p)[1];

    if (step > 0) {
      if (month > 11) {
        month = 1;
        year++;
      } else {
        month++;
      }
    } else if (step < 0) {
      if (month < 1) {
        month = 11;
        year--;
      } else {
        month--;
      }
    }

    let period = Utility.getMonthStartEnd(year, month);
    Utility.setPeriodCookie(period.start + "|" + period.end);
    this._initCalendar();
    return;
  }

  _renderSelectedPeriod(classes) {
    if (this.state.showSearchResults) {
      return "Søkeresultater";
    }
    if (typeof this.state.selectedPeriod === "undefined") {
      return;
    }

    return (
      <span className={classes.periodInfo} key={Math.random()}>
        {Utility.getMonthAndYearFromSelectedPeriodAsText(this.state.selectedPeriod)}
      </span>
    );
  }

  /**
   * Initializes the calendar view.
   * forceUpdate is called when the user has selected a specific period.
   * @param {Object} calendarDef
   */
  _initCalendar(calendarDef) {
    let periodStart = this._getStartOfMonth(),
      cal = this._getCalendarPeriod(periodStart);

    if (typeof calendarDef === "undefined" || calendarDef === null) {
      calendarDef = this.state.data;
    }

    // If a cookies has been set, read its period definition
    // and display the calendar's content accordingly.
    let period = Utility.getPeriodCookie().split("|");

    if (period !== null && period.length === 2) {
      periodStart = Utility.iso8601DateStrToDate(period[0]);
      cal = this._getCalendarPeriod(periodStart);
    }

    this.setState(
      {
        columns: 7,
        today: this.state.periodStart,
        calStartDate: cal.start,
        data: calendarDef,
        maxTiles: cal.maxTiles,
        selectedPeriod: period
        //tabValue: 0
      },
      this._loadPeriodEvents({ start: cal.dbStartdate, end: cal.dbEndDate })
    );
  }

  /**
   * Returns the date-period used when rendering the calendar, along with
   * the correspoding period used when fetching data from the database.
   */
  _getCalendarPeriod(periodStart) {
    let month = periodStart.getMonth(),
      start = this._getCalStartDate(periodStart),
      daysInMonth = Utility.getDaysInMonth(month + 1),
      dbStartDate = this._getDbStartDate(start),
      dbEndDate = this._getDbEndDate(start),
      ixLastDay = new Date(start.getFullYear(), start.getMonth(), daysInMonth).getDay(),
      maxTiles = daysInMonth + (7 - ixLastDay);

    return {
      start: start,
      dbStartdate: dbStartDate,
      dbEndDate: dbEndDate,
      maxTiles: maxTiles
    };
  }

  /**
   * Returns the Date object representing the start of the current month.
   */
  _getStartOfMonth() {
    let d = new Date();
    return new Date(d.getFullYear(), d.getMonth(), 1, 0, 0, 0);
  }

  /**
   * Gets the first date needed to render the calendar view,
   * with complete rows, where the 1st day of the month
   * is within the 1st row.
   */
  _getCalStartDate(periodStart) {
    let day = periodStart.getDay();

    let tmpDate = new Date(periodStart.getFullYear(), periodStart.getMonth(), periodStart.getDate(), 0, 0, 0);

    if (day === 1) {
      return periodStart;
    } else {
      while (tmpDate.getDay() !== 1) {
        tmpDate.setTime(tmpDate.getTime() - 24 * 60 * 60 * 1000);
        if (tmpDate.getDay() === 1) {
          break;
        }
      }
    }
    return tmpDate;
  }

  /**
   * Returns the start date used when fetching data for a period from the database.
   * @param {Date} calendarStartDate
   */
  _getDbStartDate(calendarStartDate) {
    let year = calendarStartDate.getFullYear(),
      month = calendarStartDate.getMonth();
    return Utility.dateToISO8601(new Date(year, month, 1, 0, 0, 0));
  }

  /**
   * Returns the end date used when fetching data for a period from the database.
   * @param {Date} calendarStartDate
   */
  _getDbEndDate(calendarStartDate) {
    let yearStart = calendarStartDate.getFullYear(),
      monthStart = calendarStartDate.getMonth();

    let endMonthStart = new Date(yearStart, monthStart + 2, 1, 23, 59, 59);
    let endMonthDays = Utility.getDaysInMonth(null, endMonthStart);

    return Utility.dateToISO8601(new Date(endMonthStart.getFullYear(), endMonthStart.getMonth(), endMonthDays, 23, 59, 59));
  }

  /**
   * Returns the user ID
   */
  _getUserId() {
    let userCookie = Utility.getUserCookie();
    return userCookie.uid;
  }

  /**
   * Loads data that corresponds with the selected period.
   */
  _loadPeriodEvents = period => {
    // Fetch data for the selected period
    let url = "event/" + this._correctJsZeroDate(period.start) + "_" + this._correctJsZeroDate(period.end) + "/" + this.props.calendar.id;

    Utility.apiRequest(url, "GET", data => {
      // Tag multi-day events.
      let loadedEvents = data.map(e => {
        e = Utility.tagMultiDay(e);
        return e;
      });
      this.setState({
        periodStart: Utility.iso8601DateStrToDate(period.start),
        events: loadedEvents
      });
    });
  };

  /**
   * Opens the add/edit event dialog.
   */
  _openEventDialog = (isoDate, e) => {
    // If data-action attribute has the value of 'new',
    // a blank part of the date-square has been clicked.
    // Display a clean form.
    //
    // If data-action is not present, an event has been clicked and loadEvent()
    // is fired, which loads the event data and opens a populated form.
    if (e.target.getAttribute("data-action") === "new" && typeof isoDate !== "undefined" && isoDate !== null) {
      selectedDate = isoDate;

      this.setState({
        showAddEventDialog: true
      });
    }
  };

  /**
   * Closes the edit event dialog.
   */
  _cancelAddEventDialog = e => {
    this.setState({
      selectedEvent: null,
      showAddEventDialog: false
    });
    this.forceUpdate();
  };

  /**
   * Converts the date/time in UTC-format to local date/time,
   * used when previewing the event in the calendar after an update/save,
   * before the calendar is reloaded.
   * @param {Date} date
   */
  _convertUTCDateToLocalDate(date) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
    return newDate;
  }

  /**
   * Adding +1 to the month in an ISO8601 date string.
   * Used to correct a date returned from JavaScript with
   * 0-based indexing of months to a date-format used in a server-request,
   * without a 0-based indexing of months.
   * @param {string} dateStr
   */
  _correctJsZeroDate(dateStr) {
    let year = dateStr.substring(0, 4),
      month = parseInt(dateStr.substring(4, 6), 10) + 1,
      date = dateStr.substring(6, 8);

    return year + Utility.lPad("0", 2, month.toString()) + Utility.lPad("0", 2, date);
  }

  /**
   * Renders the calendar-categories.
   */
  _renderCategories = () => {
    let eventCategories = this.state.data.eventCategories;
    return (
      <ul id="categorylist">
        {eventCategories.map(el => {
          return (
            <li key={el.id}>
              <span style={{ backgroundColor: el.color, borderColor: el.color }} />
              {el.title}
            </li>
          );
        })}
      </ul>
    );
  };

  /**
   * Renders the weekdays above the calendar.
   */
  _renderWeekdays() {
    let weekdays = ["Mandag", "Tirsdag", "Onsdag", "Torsdag", "Fredag", "Lørdag", "Søndag"];

    return (
      <ul className="weekdays" key={Math.random()}>
        {weekdays.map(w => {
          return <li key={Math.random()}>{w}</li>;
        })}
      </ul>
    );
  }

  /**
   * Renders the calendar view body.
   * @param {Object} classes
   */
  _renderBody() {
    let d = new Date(this.state.calStartDate),
      rows = [],
      cols = 7,
      cells = [],
      curMonth = null,
      self = this;

    // Get the index of the current/selected month.
    // Used when "painting" cells outside the current period grey.
    if (typeof this.state.selectedPeriod === "undefined" || this.state.selectedPeriod.length < 2) {
      curMonth = new Date().getMonth();
    } else {
      curMonth = Utility.iso8601DateStrToDate(this.state.selectedPeriod[0]).getMonth();
    }

    for (let i = 0; i < this.state.maxTiles; i = i + cols) {
      rows.push(self._renderRow(cols, d, cells, curMonth));
      cells = [];
      cols = 7;
    }

    return rows;
  }

  /**
   * Renders a single row of days in the calendar.
   */
  _renderRow = (cols, d, cells, curMonth) => {
    let cellDate = new Date(d.setDate(d.getDate()));
    for (let j = cols; j > 0; j--) {
      let eventDate = new Date(cellDate.getFullYear(), cellDate.getMonth(), cellDate.getDate()),
        isoEventDate = Utility.dateToISO8601(eventDate);

      let prevMonth = cellDate.getMonth() < curMonth || cellDate.getMonth() > curMonth;

      let calEvents = Utility.getEventsByDate(this.state.events, d.getFullYear(), d.getMonth(), new Date(cellDate).getDate());

      cells.push(this._renderEventsCell(prevMonth, isoEventDate, cellDate, calEvents));
      cellDate = new Date(d.setDate(d.getDate() + 1));
    }

    return (
      <ul key={Math.random()} className="days">
        {cells}
      </ul>
    );
  };

  /**
   * Renders the event.
   */
  _renderEventsCell(prevMonth, isoEventDate, cellDate, calEvents) {
    let isToday = new Date(cellDate).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0);

    let dayClass = prevMonth ? "day prev-month" : "day";

    if (isToday) {
      dayClass += " today";
    }

    return (
      <li key={Math.random()} className={dayClass}>
        <div onClick={this._openEventDialog.bind(this, isoEventDate)} className="date add" data-action="new">
          <span className="dateNumber">{new Date(cellDate).getDate()}</span>
        </div>

        <EventRenderer key={Math.random()} events={calEvents} categories={this.state.data.eventCategories} loadEventHandler={this.loadEvent} deleteEventHandler={this.deleteEvent} date={cellDate} />
      </li>
    );
  }

  /**
   * Updates the seatch query state
   */
  _updateSearchQuery(evt) {
    this.setState({
      searchQuery: evt.target.value
    });
  }

  /**
   * Executes a search
   */
  _doSearch(evt) {
    this.setState({
      tabValue: 1,
      showSearchResults: true
    });
    let el = document.querySelector(":focus");
    if (el) el.blur();
    evt.preventDefault();
    return false;
  }
}

export default withStyles(styles)(Calendar);
