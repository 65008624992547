import React from "react";

import { withStyles } from "@material-ui/core/styles";

import AdminUtility from "../../../../utility";

import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

const styles = theme => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  container: {
    marginTop: 15,
    marginBottom: 15
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    backgroundColor: "#eee",
    marginTop: "10px",
    marginBottom: "10px"
  }
});

/**
 * Component used to list the available COYO user-groups
 * as a list of checkboxes. Used when administering a calendar,
 * letting the user set the groups that are allowed access
 * to the given calendar.
 */
class GroupSelector extends React.Component {
  constructor(props) {
    super(props);

    let selectedGroups = [];

    if (typeof this.props.selectedGroups !== "undefined" && this.props.selectedGroups.length > 0) {
      selectedGroups = this.props.selectedGroups;
    }

    this.state = {
      groups: [],
      selectedGroups: selectedGroups
    };
  }

  componentDidMount() {
    this._getGroups();
  }

  /**
   * Handles toggling of the checkbox next
   * to the groupname, and keeping track of
   * each checkbox' state.
   */
  handleToggle = gid => () => {
    const { selectedGroups } = this.state;
    const currentIndex = selectedGroups.indexOf(gid);
    const newChecked = [...selectedGroups];

    if (currentIndex === -1) {
      newChecked.push(gid);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      selectedGroups: newChecked
    });

    this.props.onGroupSelectedHandler(newChecked); // Update parent component
  };

  render() {
    const { classes } = this.props;
    return (
      <div className="paperwrap">
        <h4 className="paperhead">Grupper med kalendertilgang</h4>
        {this.state.groups && this.state.groups.length > 0 && this._renderGroups(classes)}
      </div>
    );
  }

  /**
   * Renders the list of groups.
   * @param {object} classes
   */
  _renderGroups(classes) {
    let groups = this.state.groups;

    return (
      <FormGroup row>
        {groups.map(g => {
          return this._renderGroup(g, classes);
        })}
      </FormGroup>
    );
  }

  /**
   * Renders a the group name next to a checkbox.
   * @param {object} group
   * @param {object} classes
   */
  _renderGroup(group, classes) {
    const { selectedGroups } = this.state;
    return <FormControlLabel key={group.id} control={<Switch value="Active" defaultChecked={selectedGroups.indexOf(group.id) !== -1} onChange={this.handleToggle(group.id)} color="primary" />} label={group.displayName} />;
  }

  /**
   * Loads the list of available user groups
   * via the COYO API.
   */
  _getGroups() {
    AdminUtility.apiRequest("user/groups", "GET", data => {
      if (data === null) return;

      this.setState({ groups: data.data });
    });
  }
}

export default withStyles(styles, { withTheme: true })(GroupSelector);
