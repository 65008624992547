import React from "react";
import { withStyles } from "@material-ui/core";

import PreviewPopUp from "../../dialogs/previewpopup";
import Utility from "../../utility.js";
import moment from "moment";
import "moment/locale/nb";
moment.locale("nb");

const styles = theme => ({
  cell: {
    padding: 3
  }
});

/**
 * Component used to display the action log
 * for a calendar event.
 */
class ListViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      entries: []
    };
  }

  componentDidMount() {
    this._updateListView();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props !== prevProps) {
      this._updateListView();
    }
  }

  _updateListView() {
    if (this.props.showSearchResults && this.props.searchQuery) {
      this._loadEventsBySearchQuery(this.props.searchQuery);
    } else {
      this._loadEventsByPeriod();
    }
  }

  _loadEventsByPeriod() {
    let periodStart = moment(this.props.periodStart, "YYYYMMDD").format("YYYYMMDD");
    let periodEnd = moment(this.props.periodEnd, "YYYYMMDD")
      .add(1, "days")
      .format("YYYYMMDD");
    let url = "event/" + periodStart + "_" + periodEnd + "/" + this.props.calendarId;
    Utility.apiRequest(url, "GET", data => {
      this.setState({ entries: data });
    });
  }

  _loadEventsBySearchQuery(q) {
    let url = "event/search/" + this.props.calendarId + "/" + q;
    Utility.apiRequest(url, "GET", data => {
      this.setState({ entries: data });
    });
  }

  _closePreview = () => {
    this.setState({
      anchorEl: null,
      selectedEvent: null
    });
  };

  _showPreview = (item, event) => {
    this.setState({
      anchorEl: event.currentTarget,
      selectedEvent: item
    });
  };

  render() {
    const { classes } = this.props;
    if (this.state && this.state.entries && this.state.entries.length > 0) {
      return this._renderEntryTable(this.state.entries, classes);
    } else {
      return <div className="nocontent">Ingen elementer å vise.</div>;
    }
  }

  /**
   * Renders the table containing the log entries.
   * @param {Array} entries
   * @param {object} classes
   */
  _renderEntryTable(entries, classes) {
    return (
      <React.Fragment>
        <table className="list">
          <thead>
            <tr key={Math.random()}>
              <td className="date">
                <strong>Dato</strong>
              </td>
              <td className="date">
                <strong>Tid</strong>
              </td>
              <td className="cat">
                <strong>Kategori</strong>
              </td>
              <td className="desc">
                <strong>Hendelse</strong>
              </td>
            </tr>
          </thead>
          <tbody>
            {entries.map((entry, i) => {
              let prevEntry = i > 0 ? entries[i - 1] : null;
              return this._renderEntry(entry, prevEntry, classes);
            })}
          </tbody>
        </table>
        <PreviewPopUp selectedevent={this.state.selectedEvent} anchorel={this.state.anchorEl} categories={this.props.eventCategories} />
      </React.Fragment>
    );
  }

  /**
   * Renders a single entry as a table row.
   */
  _renderEntry(entry, prevEntry, classes) {
    let startDate = moment(entry.event_start, "DD-MM.YYYY HH:mm").toDate();
    let prevStartDate = prevEntry !== null ? moment(prevEntry.event_start, "DD-MM.YYYY HH:mm").toDate() : null;
    let endDate = moment(entry.event_end, "DD-MM.YYYY HH:mm").toDate();
    let displayDate = moment(startDate).format("DD.MM.YYYY") !== moment(prevStartDate).format("DD.MM.YYYY") ? moment(startDate).format("DD.MM.YYYY") : "";
    if (moment(startDate).format("DD.MM.YYYY") !== moment(endDate).format("DD.MM.YYYY")) {
      displayDate = moment(startDate).format("DD.MM.YYYY") + " - " + moment(endDate).format("DD.MM.YYYY");
    }

    Utility.apiRequest("event/" + entry.id, "GET", data => {
      entry.description = data.description;
    });

    entry.categories = this.props.eventCategories;
    let categoryColor = this._getCategoryColor(entry);

    let eventStatus = Utility.getEventStatusFromFields(entry.fields);
    let eventIcons = Utility.getIconsFromFields(entry.fields);

    return (
      <tr key={"listitem" + entry.id} className={eventStatus}>
        <td className="date">{displayDate}</td>
        <td className="time">
          {moment(startDate).format("HH:mm")} - {moment(endDate).format("HH:mm")}
        </td>
        <td className="cat">
          <span className="catcolor" style={{ backgroundColor: categoryColor }} />
          {this._getCategoryName(entry)}
        </td>
        <td className="desc">
          <span className="title" onClick={this.props.loadEventHandler.bind(this, entry.id)} onMouseOver={this._showPreview.bind(this, entry)} onMouseOut={this._closePreview.bind(this, entry)}>
            {entry.title}
            {eventIcons && eventIcons !== "" && (
              <span
                className="icons"
                dangerouslySetInnerHTML={{
                  __html: eventIcons
                }}
              />
            )}
            {eventStatus === "unconfirmed" && <span className="status"> - ubekreftet</span>}
            {eventStatus === "cancelled" && <span className="status"> - avlyst</span>}
          </span>
          <br />
          <br />
          {entry.description}
        </td>
      </tr>
    );
  }

  _getCategoryColor(evt) {
    let categoryColor = "#aaaaaa";
    if (typeof evt.event_category_id !== "undefined" && evt.event_category_id !== null) {
      let color = Utility.getCategoryColorFromId(evt.event_category_id, this.props.eventCategories);
      if (color !== null) {
        categoryColor = color;
      }
    }
    return categoryColor;
  }

  _getCategoryName(evt) {
    if (typeof evt.event_category_id !== "undefined" && evt.event_category_id !== null) {
      let cat = this.props.eventCategories.find(c => {
        return c.id === evt.event_category_id;
      });
      return typeof cat !== "undefined" ? cat.title : "Ukategorisert";
    } else {
      return "Ukategorisert";
    }
  }
}

export default withStyles(styles)(ListViewer);
