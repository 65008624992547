import React from "react";

import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import DeleteIcon from "@material-ui/icons/Delete";
import PrintIcon from "@material-ui/icons/Print";
import MailIcon from "@material-ui/icons/Mail";
import CheckIcon from "@material-ui/icons/CheckCircle";

import Utility from "../../utility";
import DatePicker from "material-ui-pickers/DatePicker";
import TimePicker from "material-ui-pickers/TimePicker";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";

import ActionLogViewer from "../../../actionlogviewer";
import PrintEventDetails from "../printeventdetails";
import MailEventDetails from "../maileventdetails";

import moment from "moment";
import "moment/locale/nb";
moment.locale("nb");

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: "24px 0" }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = (theme) => ({
  root: {
    color: theme.palette.text.primary,
  },
  icon: {
    margin: theme.spacing.unit,
  },
  button: {
    margin: theme.spacing.unit,
  },
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  descriptionField: {
    marginBottom: theme.spacing.unit * 3,
  },
  extraTextField: {
    marginRight: theme.spacing.unit * 2,
  },
  categoryWarningText: {
    color: "red",
    fontSize: "10px",
    marginTop: "5px",
  },
  checkbutton: {
    top: 25,
    left: 27,
    width: 15,
    color: "green",
    height: 15,
    position: "absolute",
  },
  mailbutton: {
    position: "relative",
  },
});

class EventItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      categories: props.categories,
      fieldGroups: props.fieldGroups,
      bookingOptions: props.bookingOptions,
      event: null,
      selectedFieldValues: [],
      selectedCategoryId: null,
      selectedStartDate: null,
      selectedEndDate: null,
      selectedStartTime: null,
      selectedEndTime: null,
      mainTabIx: 0,
      showDeleteDialog: false,
      showPrintDialog: false,
    };
  }

  componentWillUpdate(nextProps, nextState) {
    let selectedFieldValues = null,
      startTime = null,
      endTime = null,
      fieldGroups = null,
      categories = null,
      bookingOptions = null,
      selectedEvent = null,
      selectedCategoryId = null,
      txtTitle = "",
      txtDescription = "",
      updateState = false,
      mainTabIx = 0;

    if (
      nextProps.data !== null &&
      typeof nextProps.data.id !== "undefined" &&
      (nextState.event === null || nextState.event.id === "")
    ) {
      // An event has been selected for editing, gather information!
      this._clearReminders();
      selectedFieldValues = this._getEventSelectedFieldValues(nextProps.data);
      startTime = Utility.convertFromDbDateToDate(nextProps.data.event_start);
      endTime = Utility.convertFromDbDateToDate(nextProps.data.event_end);
      selectedEvent = nextProps.data;
      selectedCategoryId = nextProps.data.event_category_id;
      txtTitle = nextProps.data.title;
      txtDescription = nextProps.data.description;

      updateState = true;
    }

    if (
      this.state.event === null &&
      nextState.event === null &&
      (nextProps.data === null || typeof nextProps.data.id === "undefined") &&
      (nextProps.selectedStartDate === null ||
        typeof nextProps.selectedStartDate === "undefined") &&
      (nextState.selectedStartDate === null ||
        typeof nextState.selectedStartDate === "undefined") &&
      nextProps.selectedDate !== nextState.selectedDate &&
      nextProps.open
    ) {
      // No event has been selected for editing, construct start and end time based on the date selected by the user.
      let isoDate = nextProps.selectedDate;
      let initDate = Utility.iso8601DateStrToDate(isoDate);
      startTime = initDate.setHours(11);
      endTime = initDate.setHours(12);
      txtTitle = "";
      txtDescription = "";

      updateState = true;
      this._clearReminders();
    }

    if (updateState) {
      this.setState({
        event:
          typeof selectedEvent !== "undefined"
            ? selectedEvent
            : this.state.event,
        selectedCategoryId:
          typeof selectedCategoryId !== "undefined"
            ? selectedCategoryId
            : this.state.selectedCategoryId,
        selectedFieldValues: selectedFieldValues,
        fieldGroups:
          this.state.fieldGroups === null
            ? fieldGroups
            : this.state.fieldGroups,
        bookingOptions:
          this.state.bookingOptions === null
            ? bookingOptions
            : this.state.bookingOptions,
        categories:
          this.state.categories === null ? categories : this.state.categories,
        selectedStartDate: startTime,
        selectedEndDate: endTime,
        selectedStartTime: startTime,
        selectedEndTime: endTime,
        txtTitle: txtTitle,
        txtDescription: txtDescription,
        mainTabIx: mainTabIx,
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  /**
   * Switches to the selected main tab:
   * - event
   * - groups
   * - reminders
   * - action log viewer
   */
  handleMainTabChange = (event, mainTabIx) => {
    if (mainTabIx === "last") {
      mainTabIx = document.querySelectorAll(".tabcontainer").length;
    }
    this.setState({ mainTabIx });
    var els = document.querySelectorAll(".tabcontainer");
    for (var i = 0; i < els.length; i++) {
      els[i].classList.remove("active");
    }
    try {
      document.getElementById("tabcontent" + mainTabIx).classList.add("active");
    } catch (e) {}
  };

  /**
   * Handles datepicker changes.
   */
  handleDatePickerChange = (id, date) => {
    switch (id) {
      case "item-startdate":
        if (
          this.state.selectedEndDate === "" ||
          this.state.selectedEndDate === null
        ) {
          this.setState({
            selectedStartDate: date,
            selectedEndDate: date,
          });
        } else {
          this.setState({ selectedStartDate: date });
        }

        if (
          moment(date).format("YYYYMMDD") >
          moment(this.state.selectedEndDate).format("YYYYMMDD")
        ) {
          this.setState({ selectedEndDate: date });
        }
        break;
      case "item-enddate":
        this.setState({ selectedEndDate: date });
        break;
      default:
        break;
    }
  };

  /**
   * Handles timepicker changes.
   */
  handleTimePickerChange = (id, time) => {
    switch (id) {
      case "item-starttime":
        this.setState({ selectedStartTime: time });

        if (
          moment(this.state.selectedStartDate).format("YYYYMMDD") ===
            moment(this.state.selectedEndDate).format("YYYYMMDD") &&
          moment(time).format("HHmm") >
            moment(this.state.selectedEndTime).format("HHmm")
        ) {
          this.setState({ selectedEndTime: time });
        }
        break;
      case "item-endtime":
        this.setState({ selectedEndTime: time });
        break;
      default:
        break;
    }
  };

  /**
   * Update title when changed
   */
  updateTitle = (e) => {
    this.setState({ txtTitle: e.target.value });
  };

  /**
   * Update description when changed
   */
  updateDescription = (e) => {
    this.setState({ txtDescription: e.target.value });
  };

  /**
   * Renders the GUI.
   */
  render() {
    const {
      classes,
      cancelHandler,
      deleteHandler,
      saveHandler,
      fieldGroups,
      bookingOptions,
      selectedDate,
      ...other
    } = this.props;

    const {
      selectedStartDate,
      selectedEndDate,
      selectedStartTime,
      selectedEndTime,
    } = this.state;

    let groups = this.state.fieldGroups;
    if (!groups || groups.length === 0) {
      groups = [];
    }

    let reminderActive = 0;
    if (
      this.props.bookingOptions &&
      typeof this.props.bookingOptions.reminders !== "undefined" &&
      this.props.bookingOptions.reminders === true
    ) {
      reminderActive = 1;
    }

    return (
      <Dialog
        open={false}
        onCancel={this._cancelDialog}
        disableBackdropClick
        aria-labelledby="add-dialog-title"
        style={{
          margin: "10px auto",
          maxWidth: "900px",
          borderRadius: "5px",
        }}
        scroll="paper"
        fullScreen
        {...other}
      >
        {this._renderToolbar()}
        <DialogTitle id="add-dialog-title" style={{ padding: "20px" }}>
          {"Oppføring"}
        </DialogTitle>
        <DialogContent>
          {this._renderTabs()}

          <div
            className="tabcontainer active"
            id={"tabcontent0"}
            key="tabcontent1"
          >
            <TextField
              key="title"
              id="item-title"
              type="string"
              placeholder="Tittel"
              label="Tittel"
              margin="dense"
              variant="standard"
              defaultValue={
                this.state.event && this.state.event.title
                  ? this.state.event.title
                  : ""
              }
              onBlur={this.updateTitle.bind(this)}
              helperText={
                this.state.txtTitle === "" ? "Feltet må ha en verdi" : " "
              }
              error={this.state.txtTitle === ""}
              required
              fullWidth
            />

            <div className="datetime" key="datestart">
              {this._renderDatePicker(
                "item-startdate",
                selectedStartDate,
                this.handleDatePickerChange,
                "Startdato"
              )}
              {this._renderTimePicker(
                "item-starttime",
                selectedStartTime,
                this.handleTimePickerChange,
                "Starttid"
              )}
            </div>
            <div className="datetime" key="dateend">
              {this._renderDatePicker(
                "item-enddate",
                selectedEndDate,
                this.handleDatePickerChange,
                "Sluttdato"
              )}
              {this._renderTimePicker(
                "item-endtime",
                selectedEndTime,
                this.handleTimePickerChange,
                "Slutttid"
              )}
            </div>
            {this._renderCategories()}

            <TextField
              key="description"
              id="item-description"
              placeholder="Beskrivelse"
              label="Beskrivelse"
              rowsMax="6"
              className={classes.descriptionField}
              onBlur={this.updateDescription.bind(this)}
              variant="standard"
              defaultValue={
                this.props.data && this.props.data.description
                  ? this.props.data.description
                  : ""
              }
              fullWidth
              multiline
            />
          </div>

          {this._renderFieldGroupTabs(1)}
          {this._renderReminderTab(groups.length + 1)}
          {this._renderActionLogTab(groups.length + reminderActive + 1)}
        </DialogContent>
        {this._renderDialogActions()}
        {this._renderDeleteDialog(classes)}
        <PrintEventDetails
          open={this.state.showPrintDialog || false}
          selectedevent={this.state.event}
          categories={this.state.categories}
          onCancel={this._cancelPrintDialog}
        />
        <MailEventDetails
          open={this.state.showMailDialog || false}
          selectedevent={this.state.event}
          categories={this.state.categories}
          booking={this.state.bookingOptions}
          onCancel={this._cancelMailDialog}
        />
      </Dialog>
    );
  }

  /**
   * Renders a TimePicker component.
   */
  _renderTimePicker(id, value, onChange, label) {
    return (
      <TimePicker
        id={id}
        type="string"
        emptyLabel={label}
        mask={[/\d/, /\d/, ":", /\d/, /\d/]}
        ampm={false}
        placeholder={label}
        value={value}
        onChange={(e) => onChange(id, e)}
        cancelLabel="Avbryt"
        disableOpenOnEnter
        margin="dense"
        variant="standard"
        keyboard
      />
    );
  }

  /**
   * Renders a DatePicker component.
   * @param {string} id
   * @param {string} value
   * @param {function} onChange
   * @param {string} label
   */
  _renderDatePicker(id, value, onChange, label) {
    return (
      <DatePicker
        id={id}
        value={value}
        onChange={(e) => onChange(id, e)}
        placeholder={label}
        emptyLabel={label}
        type="string"
        format="DD.MM.YYYY"
        todayLabel="I dag"
        cancelLabel="Avbryt"
        showTodayButton
        margin="dense"
        keyboard
        variant="standard"
        mask={(value) =>
          value
            ? [/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/]
            : []
        }
      />
    );
  }

  /**
   * Renders the initial tab definition:
   * - Event
   * - Custom groups
   * - Action log viewer
   */
  _renderTabs() {
    const { mainTabIx } = this.state;
    const { classes } = this.props;
    let groups = this.state.fieldGroups;

    return (
      <Tabs
        value={mainTabIx}
        onChange={this.handleMainTabChange}
        className="tabWrapper"
      >
        <Tab
          className={classes.tab + " tab"}
          label="Generelt"
          key={Math.random()}
          scrollable="true"
        />
        {groups &&
          typeof groups !== "undefined" &&
          groups.length > 0 &&
          groups.map((fg, ix) => {
            return (
              <Tab
                className={classes.tab + " tab"}
                label={fg.title}
                key={Math.random()}
              />
            );
          })}
        {this.props.bookingOptions &&
          typeof this.props.bookingOptions.reminders !== "undefined" &&
          this.props.bookingOptions.reminders === true && (
            <Tab
              className={classes.tab + " tab"}
              label="Påminnelser"
              key={Math.random()}
            />
          )}
        {this.state.event && this.state.event.id !== "" && (
          <Tab
            className={classes.tab + " tab"}
            label="Endringslogg"
            key={Math.random()}
          />
        )}
        ;
      </Tabs>
    );
  }

  /**
   * Renders the top right toolbar
   */
  _renderToolbar() {
    const { classes } = this.props;
    if (!this.state.event || this.state.event.id === "") {
      return;
    }

    let reminderIsSent = false;
    if (
      this.state.event.booking &&
      typeof this.state.event.booking.confirmationList !== "undefined" &&
      this.state.event.booking.confirmationList.length > 0
    ) {
      reminderIsSent = true;
    }

    return (
      <React.Fragment>
        {this.state.event && this.state.event.id !== "" && (
          <div className="rightNav">
            {this.props.bookingOptions &&
              typeof this.props.bookingOptions.confirmations !== "undefined" &&
              this.props.bookingOptions.confirmations === true && (
                <Tooltip
                  title={
                    (reminderIsSent && "Epostbekreftelse er sendt") ||
                    "Send epostbekreftelse"
                  }
                >
                  <IconButton
                    size="small"
                    onClick={(e) => this._showMailDialog(e)}
                    className={classes.mailbutton}
                  >
                    <MailIcon />
                    {reminderIsSent && (
                      <CheckIcon className={classes.checkbutton} />
                    )}
                  </IconButton>
                </Tooltip>
              )}
            <Tooltip title="Skriv ut">
              <IconButton
                size="small"
                onClick={(e) => this._showPrintDialog(e)}
              >
                <PrintIcon />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </React.Fragment>
    );
  }

  /**
   * Renders the contents of the field group tabs.
   */
  _renderFieldGroupTabs = (id) => {
    let groups = this.state.fieldGroups;

    if (!groups || typeof groups !== "object" || groups.length === 0) {
      return "";
    } else {
      return groups.map((fieldgroup, index) => {
        return (
          <div
            className="tabcontainer"
            id={"tabcontent" + parseInt(id + index, 0)}
            key={"tabcontent" + parseInt(id + index, 0)}
          >
            {fieldgroup.fields && this._renderFields(fieldgroup.fields)}
          </div>
        );
      });
    }
  };

  _clearReminders() {
    if (
      typeof this.state.bookingOptions === "undefined" ||
      typeof this.state.bookingOptions.reminderSettings === "undefined" ||
      typeof this.state.bookingOptions.reminderSettings.length === "undefined"
    ) {
      return;
    }

    this.state.bookingOptions.reminderSettings.map((reminder, index) => {
      if (typeof reminder.active) {
        delete reminder.active;
      }
      return reminder.active;
    });
  }

  _initReminder(reminder) {
    if (
      !this.props.data ||
      typeof this.props.data.booking === "undefined" ||
      typeof this.props.data.booking.reminderReceivers === "undefined"
    ) {
      return;
    }
    let activeState = false;
    this.props.data.booking.reminderReceivers.map((id, index) => {
      if (typeof reminder === "object" && id === reminder.id) {
        activeState = true;
      }
      return activeState;
    });

    return activeState;
  }

  _handleReminderChange = (e) => {
    if (
      typeof this.state.bookingOptions === "undefined" ||
      !this.state.bookingOptions
    ) {
      return;
    }
    let bookingOptions = this.state.bookingOptions;
    bookingOptions.reminderSettings.map((reminder, index) => {
      if (reminder.id === e.target.name) {
        if (e.target.value === false) {
          reminder.active = false;
        } else {
          reminder.active = true;
        }
      }
      return false;
    });
    if (bookingOptions) {
      this.setState({
        bookingOptions,
      });
    }
  };

  /**
   * Renders the contents of the reminder tab.
   */
  _renderReminderTab = (id) => {
    const { classes } = this.props;
    if (
      !this.props.bookingOptions ||
      typeof this.props.bookingOptions.reminders === "undefined" ||
      this.props.bookingOptions.reminders === false
    ) {
      return "";
    }

    let reminders = this.state.bookingOptions.reminderSettings;
    let returnValue = "";

    if (this.props.bookingOptions.reminders === null) {
      returnValue = <p>Ingen valg for påminnelser er konfigurert.</p>;
    } else {
      // Disable option if event has passed
      let disabled = true;
      if (
        (this.state.event !== null &&
          parseInt(
            moment(this.state.event.event_start, "DD.MM.YYYY").format(
              "YYYYMMDD"
            ),
            0
          ) > parseInt(moment(new Date()).format("YYYYMMDD"), 0)) ||
        this.state.event === null
      ) {
        disabled = false;
      }

      returnValue = reminders.map((reminder, index) => {
        if (typeof reminder.active === "undefined") {
          reminder.active = this._initReminder(reminder);
        }

        return (
          <FormControl className="formcontrol" key={Math.random()} fullWidth>
            <InputLabel htmlFor={"reminder" + index}>
              {reminder.name}
            </InputLabel>
            <Select
              className={classes.selectEmpty}
              onChange={(e) => this._handleReminderChange(e)}
              value={reminder.active}
              variant="standard"
              disabled={disabled}
              inputProps={{
                name: reminder.id,
                id: reminder.id,
              }}
            >
              <MenuItem value={false} key={Math.random()}>
                Ingen påminnelse
              </MenuItem>
              <MenuItem value={true} key={Math.random()}>
                Send påminnelse
              </MenuItem>
            </Select>
          </FormControl>
        );
      });
    }

    return (
      <div
        className="tabcontainer"
        id={"tabcontent" + id}
        key={"tabcontent" + id}
      >
        {returnValue}
      </div>
    );
  };

  /**
   * Renders the contents of the action log tab.
   */
  _renderActionLogTab = (id) => {
    const { event } = this.state;
    return (
      <div
        className="tabcontainer"
        id={"tabcontent" + id}
        key={"tabcontent" + id}
      >
        <ActionLogViewer
          objectID={event && event.id !== null && event.id}
          confirmationList={
            event && event.booking && event.booking.confirmationList
          }
        />
      </div>
    );
  };

  /**
   * Renders the dialog actions
   * - delete
   * - cancel
   * - save
   */
  _renderDialogActions() {
    const { classes } = this.props;
    return (
      <DialogActions>
        {this.state.event && this.state.event.id !== "" && (
          <Button
            size="small"
            color="secondary"
            className={classes.button}
            onClick={this._confirmDeleteItem}
          >
            <DeleteIcon className={classes.icon} />
            Slett
          </Button>
        )}
        <Button
          size="small"
          color="secondary"
          className={classes.button}
          onClick={(e) => this._cancelDialog(e)}
        >
          <CancelIcon className={classes.icon} />
          Avbryt
        </Button>
        <Button
          size="small"
          color="primary"
          className={classes.button}
          onClick={(e) => this._saveItem(e)}
        >
          <SaveIcon className={classes.icon} />
          Lagre
        </Button>
      </DialogActions>
    );
  }

  /**
   * Renders the delete dialog.
   */
  _renderDeleteDialog(classes) {
    return (
      <Dialog
        open={this.state.showDeleteDialog}
        onCancel={this._cancelDeleteDialog}
        disableBackdropClick
        aria-labelledby="deleteitem-dialog-title"
      >
        <DialogTitle id="deleteitem-dialog-title">{"Slette"}</DialogTitle>
        <DialogContent>Slette valgt event?</DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            className={classes.button}
            onClick={(e) => this._cancelDeleteDialog(e)}
          >
            Avbryt
          </Button>
          <Button
            color="primary"
            className={classes.button}
            onClick={(e) => this._deleteItem(e)}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  _cancelDeleteDialog = () => {
    this.setState({ showDeleteDialog: false });
  };

  _confirmDeleteItem = () => {
    this.setState({ showDeleteDialog: true });
  };

  _deleteItem = () => {
    if (this.state.event) {
      this.props.deleteHandler(this.state.event.id);
    }
    this.setState({ showDeleteDialog: false });
  };

  /**
   * Displays the print dialog
   */
  _showPrintDialog = () => {
    this.setState({ showPrintDialog: true });
  };

  /**
   * Cancels the print dialog.
   */
  _cancelPrintDialog = () => {
    this.setState({ showPrintDialog: false });
  };

  /**
   * Displays the print dialog
   */
  _showMailDialog = () => {
    this.setState({ showMailDialog: true });
  };

  /**
   * Cancels the print dialog.
   */
  _cancelMailDialog = () => {
    this.setState({ showMailDialog: false });
  };

  /**
   * Cancels and hides the dialog.
   */
  _cancelDialog = () => {
    this._resetState();
    this.props.cancelHandler();
  };

  _resetState() {
    // HACK - Issue #83:
    // When an additional textfield is populated with a value,
    // this value is stored as a property on the state object
    // by the following syntax: <field_id>: value
    //
    // Since setState() merges the new state with the already
    // existing state, these values have to be moved manually.
    //
    // By looping through the list of fields in the current state
    // and matching them against a list of expected fields,
    // we are able to sort out the fields that have been
    // dynamically added to the state.
    // When creating a new state object, these fields' value
    // are set to null.

    let newState = {
      categories: this.props.categories,
      fieldGroups: this.props.fieldGroups,
      bookingOptions: this.props.bookingOptions,
      event: null,
      selectedFieldValues: [],
      selectedCategoryId: null,
      selectedStartDate: null,
      selectedEndDate: null,
      selectedStartTime: null,
      selectedEndTime: null,
      mainTabIx: 0,
      showDeleteDialog: false,
      showPrintDialog: false,
      showMailDialog: false,
    };

    let defaultFields = [
      "categories",
      "fieldGroups",
      "bookingOptions",
      "event",
      "selectedFieldValues",
      "selectedCategoryId",
      "selectedStartDate",
      "selectedEndDate",
      "selectedStartTime",
      "selectedEndTime",
      "mainTabIx",
      "showDeleteDialog",
      "showPrintDialog",
      "showMailDialog",
    ];

    for (var j in this.state) {
      if (!defaultFields.includes(j)) {
        newState[j] = null;
      }
    }

    this.setState(newState);
  }

  /**
   * Saves the item data.
   */
  _saveItem = () => {
    // Verify that the title-field (required) has a value.
    if (
      typeof this.state.txtTitle === "undefined" ||
      this.state.txtTitle === ""
    ) {
      return;
    }

    // Create UTC-dates for the start- and end time.
    let setStartDateParts = document
        .getElementById("item-startdate")
        .value.split("."),
      setStartTimeParts = document
        .getElementById("item-starttime")
        .value.split(":");

    let eventStart = new Date(
      Date.UTC(
        setStartDateParts[2],
        setStartDateParts[1] - 1,
        setStartDateParts[0],
        setStartTimeParts[0],
        setStartTimeParts[1],
        0
      )
    );

    let setEndDateParts = document
        .getElementById("item-enddate")
        .value.split("."),
      setEndTimeParts = document
        .getElementById("item-endtime")
        .value.split(":");

    let eventEnd = new Date(
      Date.UTC(
        setEndDateParts[2],
        setEndDateParts[1] - 1,
        setEndDateParts[0],
        setEndTimeParts[0],
        setEndTimeParts[1],
        0
      )
    );

    // Get datatypes for the event fields
    this._getSelectedFieldValuesDataType();

    // Construct the item object.
    let item = {
      id:
        typeof this.state.event !== "undefined" && this.state.event !== null
          ? this.state.event.id
          : "",
      eventStart: eventStart,
      eventEnd: eventEnd,
      title: document.getElementById("item-title").value,
      description: document.getElementById("item-description").value,
      eventCategoryId: this.state.selectedCategoryId,
      fields: [],
      booking: {},
    };

    // Add additional fields and values:

    // Add selected values from select fields.
    if (this.state.selectedFieldValues !== null) {
      let selectedFieldValues = this.state.selectedFieldValues;
      if (selectedFieldValues.length > 0) {
        item.selectedFieldValues = selectedFieldValues;
      }
    }

    // Add values from input fields.
    if (this.state.fieldGroups !== null) {
      let fieldGroups = this.state.fieldGroups;
      fieldGroups.forEach((fg) => {
        let fields = fg.fields;
        if (fields && fields.length > 0) {
          fields.forEach((f) => {
            if (f.field_type === 10) {
              // input
              let el = document.getElementById(f.id),
                value = el.value;

              item.fields.push({
                id: f.id,
                value: value,
                fieldType: f.field_type,
                dataType: f.input_type,
              });
            }
          });
        }
      });
    } else {
      item.fields = [];
    }

    // Get active reminders
    let reminderIds = [];
    if (
      this.state.bookingOptions &&
      typeof this.state.bookingOptions.reminderSettings !== "undefined" &&
      typeof this.state.bookingOptions.reminderSettings.length !== "undefined"
    ) {
      this.state.bookingOptions.reminderSettings.map((reminder, i) => {
        if (reminder.active) {
          reminderIds.push(reminder.id);
        }
        return true;
      });
    }
    if (typeof item.booking !== "object") {
      item.booking = {};
    }
    if (reminderIds.length > 0) {
      item.booking.reminderReceivers = reminderIds;
    }

    this.props.saveHandler(item);
    this._cancelDialog();
  };

  /**
   * Sets the selected category.
   */
  _setCategory = (id) => {
    let el = document.getElementById("categorywarningtext");

    if (id === this.state.selectedCategoryId) {
      this.setState({ selectedCategoryId: "" });
      if (el) {
        el.style.display = "block";
      }
    } else {
      this.setState({ selectedCategoryId: id });
      if (el) {
        el.style.display = "none";
      }
    }
    return false;
  };

  /**
   * Gets the selected field values, if any, from the
   * selected event item.
   * @param {object} data
   */
  _getEventSelectedFieldValues(data) {
    let selectedFieldValues = [];

    if (typeof data.fields !== "undefined") {
      let selectFields = data.fields.filter((f) => {
        return f.field_type === 20 || f.field_type === 30;
      });

      selectFields.forEach((f) => {
        let v = { id: f.id, values: [] };

        if (typeof f.values !== "undefined") {
          f.values.forEach((fv) => {
            v.values.push(fv.value_id);
          });

          selectedFieldValues.push(v);
        }
      });
    }

    return selectedFieldValues;
  }

  /**
   * Add the datatype from the field definition to the list of selected value.
   * For easier matching to the datatype when saving the value to the database.
   */
  _getSelectedFieldValuesDataType() {
    let selectedFieldValues =
      typeof this.state.selectedFieldValues !== "undefined"
        ? this.state.selectedFieldValues
        : null;

    if (selectedFieldValues === null) {
      return;
    }

    let fields = this.state.fields;

    if (
      typeof fields === "undefined" ||
      fields === null ||
      fields.length === 0
    ) {
      return;
    }

    selectedFieldValues.forEach((sv) => {
      fields.find((f) => {
        if (f.id === sv.id) {
          sv.fieldType = f.field_type;
          sv.dataType = f.input_type;
        }
        return true;
      });
    });
  }

  /**
   * Gets the selected time from the specified date/time string.
   */
  _getSelectedTime = (str) => {
    let d = new Date();

    if (typeof str !== "undefined" && str !== null) {
      d = new Date(str);
    }

    let dateStr =
      Utility.lPad("0", 2, d.getHours().toString()) +
      ":" +
      Utility.lPad("0", 2, d.getMinutes().toString());

    return dateStr;
  };

  /**
   * Used to determine whether or not the specified field ID
   * represents a multiple select or a single select.
   * The result is used when keeping the array of selected
   * values for the specified field in sync.
   */
  _isFieldSingleSelect(id) {
    let state = false,
      fieldGroups = this.state.fieldGroups;
    for (var n = 0; n < fieldGroups.length; n++) {
      let fg = fieldGroups[n];
      let field = fg.fields.find((f) => {
        return f.id === id;
      });

      if (typeof field !== "undefined") {
        if (field.field_type === 20) {
          state = true;
          break;
        }
      }
    }
    return state;
  }

  _handleTextFieldChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  /**
   * Stores the values selected in a multiple-select field.
   * {
   *    id: <field id>,
   *    values: [
   *      '<value id 1>',
   *      '<value id 2>',
   *      .....
   *    ]
   * }
   *
   * or stores the value selected in a select field.
   * {
   *    id: <field id>,
   *    values: '<value id>
   * }
   */
  _handleSelectChange = (e) => {
    let state = this.state,
      sfv = state.selectedFieldValues,
      selectedFieldValues =
        typeof sfv !== "undefined" && sfv !== null ? sfv : [];

    let fieldId = e.target.name,
      fieldValue = e.target.value;

    let fieldIx = selectedFieldValues.findIndex((sv) => {
      return sv.id === fieldId;
    });

    if (fieldIx === -1) {
      // Field does not exist, add it and its value.
      selectedFieldValues.push({
        id: fieldId,
        values: e.target.value,
      });
    } else {
      // Field already exist
      let field = selectedFieldValues.find((f) => {
        return f.id === fieldId;
      });

      if (this._isFieldSingleSelect(field.id)) {
        // Select lists are handled differently from multiple-select lists:

        if (field.values === fieldValue) {
          // If the selected value already exists among the selected values
          // for a single select list, remove it from the selected values,
          // emulating toggling the value.
          selectedFieldValues = selectedFieldValues.filter((sfv) => {
            return sfv.id !== fieldId;
          });
        } else {
          // The selected value has never been set for this multiple select,
          // set it.
          field.values = fieldValue;
        }
      } else {
        // fieldValue contains an array of value IDs (multiple-select)
        // that represents the selected values.
        field.values = fieldValue;
      }
    }
    this.setState({ selectedFieldValues: selectedFieldValues });
  };

  /**
   * Renders a content section for each of the field groups.
   */
  _renderFieldGroupTabContents() {
    let groups = this.state.fieldGroups;
    if (!groups || groups.length === 0) {
      return "";
    } else {
      return groups.map((fg) => {
        let sectionId = "content_" + fg.id;
        return (
          <section id={sectionId}>
            {fg.fields && this._renderFields(fg.fields)}
          </section>
        );
      });
    }
  }

  /**
   * Renders the field groups
   */
  _renderFieldGroups() {
    let groups = this.state.fieldGroups;
    if (!groups || groups.length === 0) {
      return "";
    } else {
      return (
        <div key={Math.random()}>
          {groups.map((fg) => {
            return [
              <div key={Math.random()}>{fg.title}</div>,
              <div key={Math.random()}>
                {fg.fields && this._renderFields(fg.fields)}
              </div>,
            ];
          })}
        </div>
      );
    }
  }

  /**
   * Renders the input, select and multiple-select fields.
   */
  _renderFields = (fields) => {
    /*
      INPUT = 10
      SELECT = 20
      MULTIPLE_SELECT = 30
    */

    return fields.map((f) => {
      switch (f.field_type) {
        case 10:
          return this._renderTextField(f.id, f.field_label, false);
        case 20:
          return this._renderSelectField(
            f.id,
            f.field_label,
            true,
            false,
            f.listValues
          );
        case 30:
          return this._renderSelectField(
            f.id,
            f.field_label,
            true,
            true,
            f.listValues
          );
        default:
          break;
      }
      return null;
    });
  };

  /**
   * Returns true if the specified fieldId has a
   * selected value that matches the specified valueId.
   * This will be used to mark the value as selected in GUI.
   */
  _shouldMarkSelectedValue(fieldId, valueId) {
    let selectedFieldValues = this.state.selectedFieldValues;

    if (selectedFieldValues === null) {
      return false;
    }

    let field = selectedFieldValues.find((sfv) => {
      return sfv.id === fieldId;
    });

    if (typeof field === "undefined") {
      return false;
    }

    return field.values.includes(valueId);
  }

  /**
   * Fetches the values selected in a select or multiple-select list
   * from the state and returns them as they are to be stored
   * in the value property of the field.
   */
  _getSelectedValue(fieldId, multiple) {
    let res = null;

    if (!this.state.selectedFieldValues) {
      res = [];
    } else {
      let selectedFieldValues = this.state.selectedFieldValues;

      let field = selectedFieldValues.find((f) => {
        return f.id === fieldId;
      });

      if (field) {
        if (multiple) {
          res = field.values;
        } else {
          if (Array.isArray(field.values)) {
            res = field.values[0];
          } else {
            res = field.values;
          }
        }
      } else {
        res = [];
      }
    }
    return res;
  }

  /**
   * Renders the select- and multiple-select fields.
   * @param {string} id
   * @param {string} label
   * @param {bool} required
   * @param {bool} multiple
   * @param {Array} values
   */
  _renderSelectField(id, label, required, multiple, values) {
    const { classes } = this.props;

    return (
      <FormControl className="formcontrol" key={Math.random()} fullWidth>
        <InputLabel htmlFor="dataType">{label}</InputLabel>
        <Select
          className={classes.selectEmpty}
          onChange={(e) => this._handleSelectChange(e)}
          value={this._getSelectedValue(id, multiple)}
          variant="standard"
          required={required}
          multiple={multiple}
          inputProps={{
            name: id,
            id: id,
          }}
        >
          (
          {values.map((v) => {
            let selected = this._shouldMarkSelectedValue(id, v.id);
            let item = (
              <MenuItem key={Math.random()} selected={selected} value={v.id}>
                {Utility.stripBracketsFromString(v.string_value) ||
                  v.boolean_value ||
                  v.decimal_value ||
                  v.integer_value ||
                  v.time_value ||
                  v.timestamp_value}
              </MenuItem>
            );
            return item;
          })}
          )
        </Select>
      </FormControl>
    );
  }

  /**
   * Returns the value for the specified fieldId (textfield / input-field) from
   * the loaded event data.
   * @param {string} fieldId
   * @param {object} eventData
   */
  _getLoadedTextFieldValue(fieldId, eventData) {
    let textValue = "";

    if (eventData && eventData.id !== "" && eventData.fields) {
      let field = eventData.fields.find((f) => {
        return f.id === fieldId;
      });

      if (typeof field !== "undefined") {
        if (field.values) {
          let valueObj = field.values.find((fv) => {
            return fv.field_id === fieldId;
          });
          textValue = typeof valueObj !== "undefined" ? valueObj.value : "";
        }
      }
    }
    return textValue;
  }

  /**
   * Renders the input fields.
   */
  _renderTextField(id, label, required) {
    const { classes } = this.props;
    const { event } = this.state;

    let defaultValue = this._getLoadedTextFieldValue(id, event);

    return (
      <TextField
        key={id}
        id={id}
        inputProps={{
          name: id,
          id: id,
          value: this.state[id],
        }}
        defaultValue={defaultValue ? defaultValue : ""}
        placeholder={label}
        label={label}
        margin="dense"
        className={classes.extraTextField}
        required={required}
        fullWidth
        onChange={(e) => this._handleTextFieldChange(e)}
        multiline
        variant="standard"
        rowsMax="6"
      />
    );
  }

  _getTypedTextFieldValue(id) {
    let v = this.state[id];
    return v;
  }

  /**
   * Renders the categories
   */
  _renderCategories = () => {
    const { classes } = this.props;

    let categories = this.state.categories;

    return (
      <div
        className={classes.categoriesSection}
        style={{ margin: "5px 0 10px" }}
        key={Math.random()}
      >
        <div
          style={{
            color: "rgba(0, 0, 0, 0.54)",
            padding: 0,
            fontSize: "0.8em",
            lineHeight: 1,
            margin: "8px 0 4px",
          }}
        >
          Kategori
        </div>
        {categories &&
          categories.map((cat) => {
            return (
              <Button
                key={Math.random()}
                className={classes.button}
                size="small"
                onClick={this._setCategory.bind(this, cat.id)}
                style={{ boxShadow: "none", marginLeft: "0", marginTop: "2px" }}
                variant={
                  cat.id === this.state.selectedCategoryId
                    ? "contained"
                    : "flat"
                }
              >
                <div key={cat.id}>
                  <span style={{ background: cat.color }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>{" "}
                  {cat.title}
                </div>
              </Button>
            );
          })}

        {this.renderCategoryWarningText()}
      </div>
    );
  };

  renderCategoryWarningText() {
    let event = this.state.event;
    let catId =
      event !== null && typeof event !== "undefined" && event.event_category_id;

    if (catId === "") {
      return (
        <div
          id="categorywarningtext"
          className="categoryWarningText"
          style={{
            color: "#f44336",
            padding: 0,
            fontSize: "0.8em",
            lineHeight: 1,
            margin: "-5px 0 0",
          }}
        >
          Ingen kategori valgt
        </div>
      );
    } else {
      return "";
    }
  }
}

EventItem.propTypes = {
  cancelHandler: PropTypes.func.isRequired,
  saveHandler: PropTypes.func.isRequired,
  selectedDate: PropTypes.string.isRequired,
  categories: PropTypes.array.isRequired,
  bookingOptions: PropTypes.object.isRequired,
};

export default withStyles(styles)(EventItem);
