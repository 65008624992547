import React from "react";
import CalendarSwitcher from "../user/calendar/calendarswitcher";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  switcher: {
    marginTop: 15
  }
});

/**
 * Component used to display an error-page.
 */
class ErrorPage extends React.Component {
  componentDidMount() {
    // Hide helperlinks, displayed on the root page, when displaying a specified calendar.
    let helperLinks = document.getElementById("helperlinks");
    if (helperLinks) {
      helperLinks.style.display = "none";
    }
  }

  render() {
    const { classes } = this.props;

    let message = this._getMessage();

    return (
      <div className="errorcontainer">
        <div class="icon">
          <i class="fas fa-exclamation-triangle" />
        </div>
        <h1>Feil</h1>
        <p>{message}</p>
        <div className={classes.switcher}>{/*<CalendarSwitcher />*/}</div>
      </div>
    );
  }

  _getMessage() {
    let message = "Det har oppstått en ukjent feil.",
      str = window.location.href,
      exp = /msg=(.*)/,
      matches = str.match(exp);

    if (matches !== null) {
      message = unescape(matches[1]);
    }

    return message;
  }
}

export default withStyles(styles)(ErrorPage);
