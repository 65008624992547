import config from "../config";

class Utility {
  static monthNames = [
    "Januar",
    "Februar",
    "Mars",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Desember",
  ];

  /**
   * Adds a preceding char to the specified str,
   * until the str reaches the specified size.
   * @param {str} char
   * @param {int} size
   * @param {str} str
   * @returns str
   */
  static lPad(char, size, str) {
    while (str.length < size) {
      str = char + str;
    }
    return str;
  }

  /**
   * Converts the dateObj Date() object to a string,
   * matching the ISO-8601 format: yyyymmdd
   * @param {Date} dateObj
   * @returns str
   */
  static dateToISO8601(dateObj) {
    let iso =
      dateObj.getFullYear().toString() +
      Utility.lPad("0", 2, "" + dateObj.getMonth()) +
      Utility.lPad("0", 2, dateObj.getDate().toString());
    return iso;
  }

  /**
   * Converts an ISO-8601 string on the format: yyyymmdd
   * to a Date() object.
   * @param {str} str
   * @returns Date
   */
  static iso8601DateStrToDate(str) {
    let year = str.substring(0, 4);
    let month = str.substring(4, 6);
    let day = str.substring(6, 8);
    return new Date(year, month, day, 0, 0, 0);
  }

  /**
   * Returns a formatted date from an ISO-8601 date string.
   * E.g.: 20180730 => 30.07.2018
   * @param {str} isoDate
   */
  static fromIso8601ToFormattedDate(isoDate) {
    let d = Utility.iso8601DateStrToDate(isoDate);
    return (
      Utility.lPad("0", 2, d.getDate().toString()) +
      "." +
      Utility.lPad("0", 2, d.getMonth().toString()) +
      "." +
      d.getFullYear()
    );
  }

  static getCurrentTime() {
    let d = new Date();
    let dUtc = new Date(
      Date.UTC(
        d.getFullYear(),
        d.getMonth(),
        d.getDate(),
        d.getHours(),
        d.getMinutes(),
        d.getSeconds()
      )
    );
    return (
      Utility.lPad("0", 2, dUtc.getHours().toString()) +
      ":" +
      Utility.lPad("0", 2, dUtc.getMinutes().toString())
    );
  }

  /**
   * Returns the number of days for the specified month
   * (current month if startOfMonthAsDate is not specified).
   * @param {int} month
   * @param {Date} startOfMonthAsDate
   * @returns int
   */
  static getDaysInMonth(month, startOfMonthAsDate) {
    if (!startOfMonthAsDate) {
      // If not specified, get the days in the current month
      let d = new Date();
      startOfMonthAsDate = new Date(d.getFullYear(), d.getMonth(), 1, 0, 0, 0);
    }

    return new Date(
      startOfMonthAsDate.getFullYear(),
      startOfMonthAsDate.getMonth() + 1,
      0
    ).getDate();
  }

  /**
   * Converts the specified date to a string on the format: DD.MM.YYYY HH:MM:SS
   * @param {Date} d
   */
  static convertDateToDateString(d) {
    return (
      Utility.lPad("0", 2, d.getDate().toString()) +
      "." +
      Utility.lPad("0", 2, (d.getMonth() + 1).toString()) +
      "." +
      d.getFullYear() +
      " " +
      Utility.lPad("0", 2, d.getHours().toString()) +
      ":" +
      Utility.lPad("0", 2, d.getMinutes().toString()) +
      ":" +
      Utility.lPad("0", 2, d.getSeconds().toString())
    );
  }

  /**
   * Converts the date from the string-format: 20.01.2018 12:10:38.000000
   * to a valid Date() object.
   * @param {str} str
   * @return Date
   */
  static convertFromDbDateToDate(str) {
    let parts = str.split(" ");
    let dateParts = parts[0].split(".");
    let timeParts = parts[1].split(":");
    return new Date(
      dateParts[2],
      dateParts[1] - 1,
      dateParts[0],
      timeParts[0],
      timeParts[1],
      0,
      0,
      0
    );
  }

  /**
   * Converts the event date & time to: HH:MM
   * @param {str} str
   * @returns str
   */
  static convertEventDateTimeToTime(str) {
    return str.split(" ")[1].substring(0, 5);
  }

  /**
   * Converts the event dat & time to DD.MM.YYYY
   * @param {str} str
   * @returns str
   */
  static convertEventDateTimeToDate(str) {
    return str.split(" ")[0].substring(0, 10);
  }

  /**
   * Returns the events matching the specified date.
   * @param {Array} events
   * @param {int} year
   * @param {int} month
   * @param {int} day
   * @returns Object
   */
  static getEventsByDate(events, year, month, day) {
    if (events === null || events.length === 0) {
      return null;
    }

    /*
      today: startdate >= 00:00:00.000000 and enddate <= 23:59:59.999999
      startsToday: startdate >= 00:00:00.000000 and enddate > 23:59:59.999999
      endsToday: startdate < 00:00:00.000000 and enddate <= 23:59:59.999999
      ongoing: startdate < 00:00:00.000000 and enddate > 23:59:59.999999
    */

    let startOfDay = new Date(year, month, day, 0, 0, 0, 0).getTime(),
      endOfDay = new Date(year, month, day, 23, 59, 59, 999999).getTime();

    let today = events.filter((e) => {
      let eStart = Utility.convertFromDbDateToDate(e.event_start).getTime(),
        eEnd = Utility.convertFromDbDateToDate(e.event_end).getTime();
      if (eStart >= startOfDay && eEnd <= endOfDay) {
        return e;
      }
      return null;
    });

    let startsToday = events.filter((e) => {
      let eStart = Utility.convertFromDbDateToDate(e.event_start).getTime(),
        eEnd = Utility.convertFromDbDateToDate(e.event_end).getTime();
      if (eStart >= startOfDay && eEnd > endOfDay) {
        return e;
      }
      return null;
    });

    let ongoing = events.filter((e) => {
      let eStart = Utility.convertFromDbDateToDate(e.event_start).getTime(),
        eEnd = Utility.convertFromDbDateToDate(e.event_end).getTime();
      if (eStart < startOfDay && eEnd > endOfDay) {
        return e;
      }
      return null;
    });

    let endsToday = events.filter((e) => {
      let eStart = Utility.convertFromDbDateToDate(e.event_start).getTime(),
        eEnd = Utility.convertFromDbDateToDate(e.event_end).getTime();

      if (eStart < startOfDay && eEnd <= endOfDay) {
        return e;
      }
      return null;
    });

    let allEvents = [];
    allEvents = allEvents.concat(ongoing, today, startsToday, endsToday);
    return allEvents;
  }

  /**
   * Executes an async-call against the specified URL using the specified method with the specified body.
   * Specified callback is executed when the result is returned.
   * @param {string} url
   * @param {string} method
   * @param {function} callback
   * @param {Object} body
   * @param {Boolean} overrideUser (optional)
   */
  static async apiRequest(url, method, callback, body, overrideUser = false) {
    let API_URL = config.userUrl,
      userCookie = Utility.getUserCookie(),
      COYO_USER_ID = userCookie.uid;

    if (overrideUser) {
      COYO_USER_ID = config.userId;
    }

    let reqParams = {
      method: method,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
        Accept: "application/json",
        "Content-Type": "application/json",
        "Coyo-User": COYO_USER_ID,
      },
    };

    if (method !== "GET") {
      if (typeof body === "undefined" || body === null) {
        body = JSON.stringify({ userId: COYO_USER_ID });
      } else {
        body.userId = COYO_USER_ID;
        body = JSON.stringify(body);
      }

      reqParams.body = body;
    }

    fetch(API_URL + url, reqParams)
      .then((res) => {
        if (!res.ok) {
          if (res.status === 401) {
            throw Error(
              "Ingen tilgang, vennligst ta kontakt med systemadministrator."
            );
          } else {
            throw Error(
              "Det har oppstått en ukjent feil, vennligst ta kontakt med systemadministrator."
            );
          }
        }
        return res.json();
      })
      .then((data) => {
        if (data.status === "success" && data.data) {
          callback(data.data);
        } else if (data.status === "error") {
          if (data.message) {
            throw Error(data.message);
          } else {
            throw Error(
              "Det har oppstått en ukjent feil, vennligst ta kontakt med systemadministrator."
            );
          }
        }
      })
      .catch((err) => {
        console.error('API-request failed: ', err);

        // An exception occured, redirecting to error page.
        window.location.replace("/error/?msg=" + err);
        throw err;
      });
  }

  /**
   * Returns the color for the category matching the specified ID
   * in the list of categories.
   * @param {int} id
   * @param {Array} categories
   */
  static getCategoryColorFromId(id, categories) {
    let cat = categories.find((c) => {
      return c.id === id;
    });

    return typeof cat !== "undefined" ? cat.color : null;
  }

  /**
   * Returns the start and end of the current month as ISO-8601 dates:
   * @param {int} year
   * @param {int} month
   * @param returns {obj} { start: '20180501',end: '20180530' }
   */
  static getMonthStartEnd(year, month) {
    let startP = new Date(year, month, 1, 0, 0, 0);
    let endP = new Date(year, month, Utility.getDaysInMonth(month), 23, 59, 59);

    return {
      start: Utility.dateToISO8601(startP),
      end: Utility.dateToISO8601(endP),
    };
  }

  /**
   * Tags multiday items.
   * @param {Object} item
   */
  static tagMultiDay(item) {
    let starts = Utility.convertFromDbDateToDate(item.event_start),
      ends = Utility.convertFromDbDateToDate(item.event_end);

    if (Utility.dateToISO8601(starts) === Utility.dateToISO8601(ends)) {
      item.multiDay = false;
    } else {
      item.multiDay = true;
    }

    return item;
  }

  /**
   * Store the selected period in a cookie, for future reference.
   */
  static setPeriodCookie(period) {
    let cookieName = "coyocalendarperiod",
      expires = new Date();

    expires.setDate(expires.getDate() + 180);
    document.cookie =
      cookieName +
      "=" +
      period +
      "; expires=" +
      expires.toString() +
      "; path=/; SameSite=None; Secure";
  }

  /**
   * Store the userId in a cookie, for future reference.
   * @param {object} json
   */
  static setUserCookie(data) {
    let cookieName = "u",
      expires = new Date();

    if (data !== null) {
      expires.setDate(expires.getDate() + 180);
      document.cookie =
        cookieName +
        "=" +
        JSON.stringify(data) +
        "; expires=" +
        expires.toString() +
        "; path=/; SameSite=None; Secure";
    } else if (data === null) {
      document.cookie =
        cookieName + "=; expires=0; path=/; SameSite=None; Secure";
    }
  }

  /**
   * Fetch the period cookie.
   */
  static getPeriodCookie() {
    let result = null;

    if (!document.cookie) {
      Utility.createCurrentPeriod();
      result = Utility.getPeriodCookie();
    } else {
      let cookieStr = document.cookie;

      let cookies = cookieStr.split(";");

      cookies.forEach((c) => {
        let parts = c.split("=");
        if (parts[0].toString().trim() === "coyocalendarperiod") {
          result = parts[1].toString().trim();
        }
      });

      if (result === null) {
        Utility.createCurrentPeriod();
        result = Utility.getPeriodCookie();
      }
    }

    return result;
  }

  /**
   * Returns the userID.
   */
  static getUserCookie() {
    let result = null;
    if (document.cookie) {
      let cookies = document.cookie.split(";");
      cookies.forEach((c) => {
        let parts = c.split("=");
        if (parts[0].toString().trim() === "u") {
          result = parts[1].toString().trim();
        }
      });
    }
    return JSON.parse(result === "" ? null : result);
  }

  /**
   * If period cookie has been set, this method sets the period cookie,
   * based on the current month/year.
   */
  static createCurrentPeriod() {
    let d = new Date(),
      maxDays = Utility.getDaysInMonth(d.getMonth()),
      month = d.getMonth(),
      year = d.getFullYear();

    let period =
      Utility.dateToISO8601(new Date(year, month, 1, 0, 0, 0)) +
      "|" +
      Utility.dateToISO8601(new Date(year, month, maxDays, 23, 59, 59));

    Utility.setPeriodCookie(period);
  }

  /**
   * Returns the month number and year from the selected period.
   * @param {Array} period
   */
  static getMonthAndYearFromSelectedPeriod(period) {
    if (typeof period.length === "undefined" || period.length < 2) {
      return "";
    }

    let p = period[0].toString(),
      year = p.substring(0, 4),
      month = p.substring(4, 6);

    return [parseInt(month, 10), year];
  }

  /**
   * Returns the monthname and year from the selected period.
   * @param {Array} period
   */
  static getMonthAndYearFromSelectedPeriodAsText(period) {
    if (typeof period.length === "undefined" || period.length < 2) {
      return "";
    }

    let p = period[0].toString(),
      year = p.substring(0, 4),
      month = p.substring(4, 6);

    let monthName = Utility.monthNames[parseInt(month, 10)];

    return monthName + " " + year;
  }

  /**
   * Check for a custom select field named "status" and identify confirmed or unconfirmed events.
   * @param {Object} fields
   */
  static getEventStatusFromFields(fields) {
    try {
      for (var i in fields) {
        if (
          fields[i].field_type === 20 &&
          fields[i].label.toLowerCase() === "status"
        ) {
          let stats = this.getEventStatusFromField(fields[i]);
          if (stats !== "") {
            return stats;
          }
        }
      }
      return "";
    } catch (e) {
      //console.log("Error");
    }
  }

  /**
   * Identify confirmed or unconfirmed events.
   * @param {Object} field
   */
  static getEventStatusFromField(field) {
    for (var j in field.values) {
      switch (field.values[j].value.toLowerCase()) {
        case "ubekreftet":
          return "unconfirmed";
        case "bekreftet":
          return "confirmed";
        case "avlyst":
          return "cancelled";
        default:
          return "";
      }
    }
  }

  /**
   * Check for "[icon=##]" in field label.
   * @param {Object} fields
   */
  static getIconsFromFields(fields) {
    let icons = "";
    try {
      for (var i in fields) {
        if (fields[i].field_type === 20 || fields[i].field_type === 30) {
          icons = icons + this.getIconsFromField(fields[i]);
        }
      }
      return icons;
    } catch (e) {
      //console.log("Error");
    }
  }

  /**
   * Check for "epost" in field label.
   * @param {Object} fields
   */
  static getEmailFromFields(fields) {
    try {
      for (var i in fields) {
        if (fields[i].field_type === 10) {
          if (fields[i].label.toLowerCase() === "epost") {
            return fields[i].values[0].value;
          }
        }
      }
      return "";
    } catch (e) {
      //console.log("Error");
    }
  }

  /**
   * Identify icon in field
   * @param {Object} field
   */
  static getIconsFromField(field) {
    let icons = "";
    for (var j in field.values) {
      let currentField = field.values[j].value.toLowerCase();
      let match = currentField.match(/\[icon=(.*)\]/);
      if (match && typeof config.fieldIcons[match[1]] !== "undefined") {
        icons = icons + config.fieldIcons[match[1]];
      }
    }
    return icons;
  }

  /**
   * Remove bracket content from text.
   * @param {String} text
   */
  static stripBracketsFromString(text) {
    text = text + " ";
    return text.replace(/\[(.*?)\]/, "").trim();
  }

  /**
   * Insert <br/> element when line break occurs
   * @param {String} text
   */
  static lineBreakToBr(text) {
    if (typeof text === "string") {
      return text.replace(/(?:\r\n|\r|\n)/g, "<br>");
    } else {
      return text;
    }
  }

  /**
   * Returns the value of a query string
   * @param {String} name
   * @param {String} url (optional)
   */
  static getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  /**
   * Converts an ActionLog action type ID to a human readable string.
   */
  static actionTypeToHr(actionType) {
    switch (parseInt(actionType, 10)) {
      case 100:
        return "Opprettet kalender";
      case 110:
        return "Oppdatert kalender";
      case 120:
        return "Deaktivert kalender";
      case 130:
        return "Aktivert kalender";
      case 140:
        return "Slettet kalender";
      case 150:
        return "Lagt til felt";
      case 160:
        return "Slettet felt";
      case 170:
        return "Opprettet hendelse";
      case 200:
        return "Opprettet hendelse";
      case 210:
        return "Oppdatert hendelse";
      case 220:
        return "Deaktivert hendelse";
      case 230:
        return "Slettet hendelse";
      case 300:
        return "Opprettet hendelseskategori";
      case 310:
        return "Oppdatert hendelseskategori";
      case 320:
        return "Deaktivert hendelseskategori";
      case 330:
        return "Aktivert hendelseskategori";
      case 340:
        return "Slettet hendelseskategori";
      case 400:
        return "Opprettet felt";
      case 410:
        return "Oppdatert felt";
      case 420:
        return "Deaktivert felt";
      case 430:
        return "Aktivert felt";
      case 440:
        return "Slettet felt";
      case 500:
        return "Listeverdi opprettet";
      case 510:
        return "Listeverdi oppdatert";
      case 540:
        return "Listeverdi slettet";
      case 545:
        return "Listeverdi slettet alle";
      case 600:
        return "Bekreftelse sendt";
      default:
        return;
    }
  }
}

// Export class
export default Utility;
