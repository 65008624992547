import React from "react";

import { withStyles } from "@material-ui/core";
import Utility from "../../utility";
import config from "../../../config";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import Button from "@material-ui/core/Button";
import CancelIcon from "@material-ui/icons/Cancel";
import MailIcon from "@material-ui/icons/Mail";

const styles = theme => ({
  root: {
    color: theme.palette.text.primary
  },
  icon: {
    margin: theme.spacing.unit
  },
  button: {
    margin: theme.spacing.unit
  }
});

/**
 * Component used to display a preview of the
 * calendar event when hovering over it.
 */
class MailEventDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedEvent: this.props.selectedevent,
      booking: this.props.booking
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.selectedEvent !== nextProps.selectedevent) {
      this.setState({
        selectedEvent: nextProps.selectedevent
      });
    }
    this.render();
  }

  render() {
    const { selectedEvent } = this.state;
    const { classes, ...other } = this.props;

    if (!selectedEvent) {
      return "";
    }

    return (
      <Dialog
        open={false}
        onCancel={this._cancelDialog}
        aria-labelledby="add-dialog-title"
        style={{
          margin: "10px auto",
          maxWidth: "900px",
          borderRadius: "5px"
        }}
        scroll="paper"
        fullScreen
        {...other}>
        <DialogTitle id="add-dialog-title" style={{ padding: "20px" }}>
          {"Send bekreftelse"}
        </DialogTitle>
        <DialogContent>
          <form id="emailForm">
            <label htmlFor="sender">Avsender</label>
            <input id="sender" type="text" name="sender" defaultValue={this._getEmailSender()} />
            <label htmlFor="receiver">Mottaker</label>
            <input id="receiver" type="text" name="receiver" defaultValue={this._getReceiverEmailAddress()} />
            <label htmlFor="subject">Emnefelt</label>
            <input id="subject" type="text" name="receiver" defaultValue="Bestillingsbekreftelse" />
            <label htmlFor="body">Melding</label>
            <textarea id="body" name="body" defaultValue={this._getBodyText(selectedEvent)} />
          </form>
        </DialogContent>
        {this._renderDialogActions()}
      </Dialog>
    );
  }

  _getEmailSender() {
    let senderEmail = "";
    if (this.props.booking && typeof this.props.booking.confirmationSender !== "undefined") {
      senderEmail = this.props.booking.confirmationSender;
    } else {
      senderEmail = config.confirmationSender;
    }
    return senderEmail;
  }

  _getBodyText(evt) {
    let _this = this;
    let bodyText = "";

    if (this.props.booking && typeof this.props.booking.confirmationText !== "undefined") {
      bodyText = String(this.props.booking.confirmationText);
    } else {
      bodyText = String(config.confirmationText) + "---";
    }

    return bodyText.replace(/\{\{(.+?)\}\}/g, function(match) {
      let param = match.replace(/[{{}}]/g, "");
      switch (param) {
        case "title":
          return evt.title;
        case "description":
          return evt.description;
        case "start_date":
          return Utility.convertEventDateTimeToDate(evt.event_start);
        case "start_time":
          return Utility.convertEventDateTimeToTime(evt.event_start);
        case "end_date":
          return Utility.convertEventDateTimeToDate(evt.event_end);
        case "end_time":
          return Utility.convertEventDateTimeToTime(evt.event_end);
        case "category":
          return _this._getCategoryName(evt);
        default:
          if (param.indexOf("custom.") === 0) {
            let customParam = param
              .replace("custom.", "")
              .replace(" ", "_")
              .toLowerCase();
            try {
              return evt.fields
                .map(field => {
                  let label = field.label.replace(" ", "_").toLowerCase();
                  if (label === customParam) {
                    return _this._getValueFromField(field);
                  }
                  return null;
                })
                .join("");
            } catch (e) {
              return "";
            }
          } else {
            return "";
          }
      }
    });
  }

  _getReceiverEmailAddress() {
    if (typeof this.props.selectedevent === "undefined" || this.props.selectedevent === null || typeof this.props.selectedevent.fields === "undefined") {
      return "";
    }
    return Utility.getEmailFromFields(this.props.selectedevent.fields);
  }

  _getCategoryName(evt) {
    if (typeof evt.event_category_id !== "undefined" && evt.event_category_id !== null) {
      let cat = this.props.categories.find(c => {
        return c.id === evt.event_category_id;
      });
      return typeof cat !== "undefined" ? cat.title : "Ukategorisert";
    } else {
      return "Ukategorisert";
    }
  }

  _getValueFromField(field) {
    if (typeof field.values === "undefined" || field.values === "" || (typeof field.values === "object" && typeof field.values[0] === "undefined")) {
      return "";
    } else {
      return field.values.map(fv => {
        return Utility.stripBracketsFromString(fv.value);
      });
    }
  }

  /**
   * Renders the dialog actions
   * - close
   */
  _renderDialogActions() {
    const { classes } = this.props;
    return (
      <DialogActions>
        <Button size="small" color="primary" className={classes.button} onClick={e => this._sendEmail(e)}>
          <MailIcon className={classes.icon} />
          Send epost
        </Button>
        <Button size="small" color="secondary" className={classes.button} onClick={e => this._cancelDialog(e)}>
          <CancelIcon className={classes.icon} />
          Lukk
        </Button>
      </DialogActions>
    );
  }

  /**
   * Cancels and hides the dialog.
   */
  _cancelDialog = () => {
    this.props.onCancel();
  };

  /**
   * Executes printing of the dialog
   */
  _sendEmail = () => {
    let confirmationData = {
      eventId: this.state.selectedEvent.id,
      userId: Utility.getUserCookie().uid,
      mailBody: document.getElementById("body").value,
      mailSubject: document.getElementById("subject").value,
      mailSender: document.getElementById("sender").value,
      mailReceiver: document.getElementById("receiver").value
    };
    Utility.apiRequest(
      "sendconfirmation",
      "POST",
      data => {
        alert("Bekreftelsen er sendt.");
      },
      confirmationData
    );
    this.props.onCancel();
  };
}

export default withStyles(styles)(MailEventDetails);
