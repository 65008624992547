import React from "react";
import ReactDOM from "react-dom";
import "./css/styles.css";

import Admin from "./components/admin";
import Calendar from "./components/user/calendar";
import ErrorPage from "./components/errorpage";
import Utility from "./components/user/utility";
import Shim from "./shim";
import User from "./components/user";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import ReactLoading from "react-loading";
import moment from "moment";
import "moment/locale/nb";
import MomentUtils from "material-ui-pickers/utils/moment-utils";
import MuiPickersUtilsProvider from "material-ui-pickers/utils/MuiPickersUtilsProvider";

moment.locale("nb");

new Shim(); // Init shims

class App extends React.Component {
  constructor() {
    super();

    this.state = {
      links: [],
      fetchingData: true,
    };
  }

  componentDidMount() {
    this._getUserId().then((info) => {
      console.info(info);

      this.setState({ fetchingData: false });
      Utility.apiRequest("calendar", "GET", (data) => {
        this.renderCalendarRoutes(data);

        // Hide helperlinks, displayed on the root page, when displaying a specified calendar.
        let helperLinks = document.getElementById("helperlinks");
        if (helperLinks) {
          helperLinks.style.display = "none";
        }
      });
    });
  }

  /**
   * Renders the calendar routes.
   */
  renderCalendarRoutes(data) {
    let links = [];

    for (let i = 0; i < data.length; i++) {
      links.push({ id: data[i].id, title: data[i].title });
    }

    this.setState({
      links: links,
    });
  }

  /**
   * Renders the content of the requested route.
   */
  render() {
    console.log('fetching data: ', this.state.fetchingData);
    return !this.state.fetchingData ? (
      <MuiPickersUtilsProvider utils={MomentUtils} moment={moment} locale="nb">
        <div>
          <div id="helperlinks">
            <ul>
              <li key={Math.random()}>
                <a href="/admin">Administrasjon</a>
              </li>
              {this.state.links.length > 0 &&
                this.state.links.map((l) => {
                  return (
                    <li key={Math.random()}>
                      <a href={"/calendar/" + l.id}>{l.title}</a>
                    </li>
                  );
                })}
            </ul>
          </div>
          <Router>
            <Switch>
              <Route exact path="/" component={ErrorPage} />
              <Route exact path="/error" component={ErrorPage} />
              <Route exact path="/calendar" component={Calendar} />
              <Route exact path="/admin" component={Admin} />
              {this.state.links.length > 0 &&
                this.state.links.map((el) => {
                  return (
                    <Route
                      key={Math.random()}
                      exact
                      path={"/calendar/" + el.id}
                      component={User}
                    />
                  );
                })}
            </Switch>
          </Router>
        </div>
      </MuiPickersUtilsProvider>
    ) : (
      <ReactLoading type="cubes" color="#aaa" className="loader" />
    );
  }

  _scrapeUserIdFromUrl() {
    let uid = null,
      str = window.location.href,
      exp = /u=([\d|\w|]{1,}-[\d|\w]{1,}-[\d|\w]{1,}-[\d|\w]{1,}-[\d|\w]{1,})/,
      matches = str.match(exp);

    if (matches !== null && matches.length === 2) {
      uid = matches[1];
    }

    return uid;
  }

  /**
   * Creates a cookie containing the specified UserID for later use.
   * Cookie-name: u
   */
  _getUserId() {
    return new Promise((resolve, reject) => {
      let uid = this._scrapeUserIdFromUrl(),
        cookie = Utility.getUserCookie();

      if (cookie === null && uid === null) {
        reject("Unable to init user session");
      }

      if (uid !== null) {
        console.info("Initializing new user session.");

        let newCookie = this._initCookieBase(cookie, uid);

        if (cookie !== newCookie) {
          Utility.setUserCookie(newCookie);
        }

        // Load user data
        Utility.apiRequest("data/" + uid, "GET", (data) => {
          if (
            data.data.cids === null ||
            (data.data.cids.length === 0 && !data.data.adm)
          ) {
            reject("User is not granted access to any calendars.");
          } else {
            Object.assign(newCookie, data.data);
            Utility.setUserCookie(newCookie);
            resolve("...user session initialized.");
          }
        });
      } else if (cookie !== null) {
        resolve("Using existing user session.");
      }
    });
  }

  _initCookieBase(cookie, uid) {
    if (cookie !== null && cookie.uid === uid) {
      return cookie;
    } else if (cookie === null || cookie.uid !== uid) {
      // If no cookie has been set, init the cookie base object.
      return {
        uid: uid,
        cids: [],
        adm: false,
      };
    }
  }
}

export default App;
ReactDOM.render(<App />, document.getElementById("root"));
