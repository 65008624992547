import React from "react";

import { withStyles } from "@material-ui/core/styles";
import config from "../../../../../config";
import Utility from "../../../../utility";

import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

const styles = theme => ({
  root: {
    color: theme.palette.text.primary
  },
  icon: {
    margin: theme.spacing.unit
  },
  button: {
    margin: theme.spacing.unit
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    backgroundColor: "#eee",
    marginTop: 10
  },
  cards: {
    margin: "10px 0",
    padding: "0 16px"
  },
  card: {
    padding: "0 !important"
  },
  table: {
    marginBottom: 30
  },
  nocontent: {
    marginTop: 0,
    marginBottom: 20
  }
});

let selectedReminder = {};
let oldReminder = {};

/**
 * Component used to list the available COYO user-groups
 * as a list of checkboxes. Used when administering a calendar,
 * letting the user set the groups that are allowed access
 * to the given calendar.
 */
class BookingOptions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDeleteReminderDialog: false,
      showEditReminderDialog: false
    };
  }

  componentDidMount() {}

  /**
   * Track changes in booking confirmation toggle
   */
  onToggleBookingConfirmations = () => {
    let bookingProp = this.props.booking;
    bookingProp.confirmations = !bookingProp.confirmations;
    this.props.onBookingOptionsChanges(bookingProp);
  };

  /**
   * Track changes in booking toggle
   */
  onToggleBookingReminders = () => {
    let bookingProp = this.props.booking;
    bookingProp.reminders = !bookingProp.reminders;
    this.props.onBookingOptionsChanges(bookingProp);
  };

  /**
   * Track changes in booking confirmation text
   */
  onChangeConfirmationText = e => {
    let bookingProp = this.props.booking;
    bookingProp.confirmationText = e.target.value;
    this.props.onBookingOptionsChanges(bookingProp);
  };

  /**
   * Track changes in booking sender email address
   */
  onChangeFromEmail = e => {
    let bookingProp = this.props.booking;
    bookingProp.confirmationSender = e.target.value;
    this.props.onBookingOptionsChanges(bookingProp);
  };

  onReminderNameUpdate = e => {
    if (typeof e.target !== "undefined") {
      selectedReminder.name = e.target.value;
    }
  };

  onReminderMailUpdate = e => {
    if (typeof e.target !== "undefined") {
      selectedReminder.email = e.target.value;
    }
  };

  openEditReminderDialog = data => {
    oldReminder = { name: data.name, email: data.email, id: data.id };
    if (typeof data.name === "undefined") {
      selectedReminder = { name: "", email: "" };
    } else {
      selectedReminder = data;
    }

    this.setState({
      showEditReminderDialog: true
    });
  };

  openDeleteReminderDialog = data => {
    selectedReminder = data;
    this.setState({ showDeleteDialog: true });
  };

  cancelDeleteDialog = e => {
    selectedReminder = [];
    this.setState({ showDeleteDialog: false });
  };

  cancelEditReminderDialog = () => {
    // Force reset reminder
    let bookingCache = this.props.booking;
    this.props.booking.reminderSettings.map((el, i) => {
      if (el.id === oldReminder.id) {
        bookingCache.reminderSettings[i] = oldReminder;
      }
      return null;
    });
    this.props.onBookingOptionsChanges(bookingCache);

    selectedReminder = [];
    this.setState({ showEditReminderDialog: false });
  };

  submitReminder = () => {
    if (selectedReminder === {}) {
      this.cancelDeleteDialog();
      return;
    }

    // Add reminder to prop
    if (typeof selectedReminder.id === "undefined") {
      selectedReminder.id = Utility.createUUID();
      this.props.booking.reminderSettings.push(selectedReminder);
    }
    this.props.onBookingOptionsChanges(this.props.booking);

    this.setState({
      showEditReminderDialog: false
    });
    this.cancelDeleteDialog();
  };

  deleteReminder = () => {
    if (selectedReminder === {}) {
      this.cancelDeleteDialog();
      return;
    }

    // Remove reminder from prop
    let bookingCache = this.props.booking;
    this.props.booking.reminderSettings.map((el, i) => {
      if (el.id === selectedReminder.id) {
        bookingCache.reminderSettings = this.props.booking.reminderSettings.filter((e, j) => {
          return j !== i;
        });
      }
      return null;
    });
    this.props.onBookingOptionsChanges(bookingCache);

    this.setState({
      showDeleteDialog: false
    });
    return;
  };

  render() {
    const { classes, booking, fieldGroups } = this.props;
    let confirmationFields = ["{{title}}", "{{description}}", "{{category}}", "{{start_time}}", "{{start_date}}", "{{end_time}}", "{{end_date}}"];
    if (fieldGroups && fieldGroups.length > 0) {
      for (let i = 0; i < fieldGroups.length; i++) {
        if (typeof fieldGroups[i].fields !== "undefined") {
          for (let j = 0; j < fieldGroups[i].fields.length; j++) {
            let label = "{{custom." + fieldGroups[i].fields[j].field_label.toLowerCase().replace(" ", "_") + "}}";
            confirmationFields.push(label);
          }
        }
      }
    }

    let settings = [];
    if (typeof booking.reminderSettings !== "undefined" && booking.reminderSettings !== null) {
      settings = booking.reminderSettings;
    }
    try {
      settings.map((el, i) => {
        if (!el.id || typeof el.id === "undefined") {
          el.id = Utility.createUUID();
        }
        return null;
      });
    } catch (e) {
      settings = [];
      this.props.booking.reminderSettings = [];
    }

    return (
      <React.Fragment>
        {booking.reminders && (
          <React.Fragment>
            <div className="paperheadwrap">
              <h4 className="paperhead">Epostpåminnelser</h4>
              <Button classes={classes.button} color="primary" onClick={this.openEditReminderDialog} variant="flat">
                Legg til
              </Button>
            </div>
            {(settings.length === 0 && <p className={classes.nocontent}>Ingen epostadresser for påminnelser er registrert.</p>) || (
              <Table className={classes.table}>
                <TableHead>
                  <TableRow key="tr-1">
                    <TableCell key="tc-1">Gruppe</TableCell>
                    <TableCell key="tc-2">Epostmottaker(e)</TableCell>
                    <TableCell key="tc-3" numeric="true" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {settings.map((el, i) => {
                    return (
                      <TableRow hover>
                        <TableCell key={"tc-1" + i}>{el.name}</TableCell>
                        <TableCell key={"tc-2" + i}>{el.email}</TableCell>
                        <TableCell key={"tc-3" + i} numeric="true">
                          {!el.deleted && el.id !== "" && (
                            <IconButton className={classes.button} color="primary">
                              <EditIcon onClick={this.openEditReminderDialog.bind(this, el)} />
                            </IconButton>
                          )}
                          {!el.deleted && (
                            <IconButton className={classes.button} onClick={this.openDeleteReminderDialog.bind(this, el)}>
                              <DeleteIcon />
                            </IconButton>
                          )}
                          {el.deleted && "Merket for sletting"}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </React.Fragment>
        )}
        {booking.confirmations && (
          <React.Fragment>
            <div className="paperheadwrap">
              <h4 className="paperhead">Mal for epostbekreftelser</h4>
            </div>
            <div className="textareawrap">
              <TextField
                className={classes.textfield}
                key="confirmation"
                id="booking-confirmation"
                placeholder="Epostmal"
                rowsMax="12"
                onBlur={this.onChangeConfirmationText.bind(this)}
                variant="filled"
                margin="normal"
                defaultValue={booking.confirmationText.length > 5 ? booking.confirmationText : config.confirmationText}
                fullWidth
                multiline
              />
            </div>
            <div>
              Følgende strofer kan settes inn i teksten for å inkludere info fra databasen: <em>{confirmationFields.join(", ")}</em>
            </div>
            <TextField
              className={classes.textfield}
              key="sender"
              id="booking-sender"
              placeholder="Avsenderadresse"
              label="Avsenderadresse"
              rowsMax="12"
              onBlur={this.onChangeFromEmail.bind(this)}
              variant="filled"
              margin="normal"
              defaultValue={booking.confirmationSender.length > 5 ? booking.confirmationSender : config.confirmationSender}
              fullWidth
            />
          </React.Fragment>
        )}
        <Dialog open={this.state.showDeleteDialog}>
          <DialogTitle>Bekreft sletting</DialogTitle>
          <DialogContent>Er du sikker?</DialogContent>
          <DialogActions>
            <Button className={classes.icon} onClick={this.cancelDeleteDialog} color="secondary">
              Avbryt
            </Button>
            <Button className={classes.icon} onClick={this.deleteReminder} color="primary">
              Slett
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.showEditReminderDialog}>
          <DialogTitle>Påminnelse</DialogTitle>
          <DialogContent>
            <TextField className={classes.textfield} key="reminder-name" id="reminder-name" placeholder="Navn på gruppe" label="Navn på gruppe" type="text" variant="filled" margin="normal" defaultValue={selectedReminder.name} onChange={e => this.onReminderNameUpdate(e)} fullWidth />
            <TextField className={classes.textfield} key="reminder-email" id="reminder-email" placeholder="Epostmottaker" label="Epostmottaker(e)" type="text" variant="filled" margin="normal" defaultValue={selectedReminder.email} onChange={e => this.onReminderMailUpdate(e)} fullWidth />
          </DialogContent>
          <DialogActions>
            <Button className={classes.icon} onClick={this.cancelEditReminderDialog} color="secondary">
              Avbryt
            </Button>
            <Button className={classes.icon} onClick={this.submitReminder} color="primary">
              Lagre
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(BookingOptions);
