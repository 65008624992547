import React from 'react';
import CalendarList from './calendar/index';
import '../../../node_modules/elemental/less/elemental.less';

/**
 * Component used to render the list of available
 * calendars, displayed as cards.
 */
class Admin extends React.Component {
    componentDidMount() {
        // Hide helperlinks, displayed on the root page, when displaying a specified calendar.
        let helperLinks = document.getElementById("helperlinks");
        if (helperLinks) {
            helperLinks.style.display = "none";
        }
    }
    
    render() {
        return (
            <div>
                <CalendarList></CalendarList>   
            </div>
        )
    }
}

export default Admin;
