import React from "react";
import Utility from "../../utility";

import IconButton from "@material-ui/core/IconButton";
import SettingsIcon from "@material-ui/icons/Settings";

import { withStyles } from "@material-ui/core";

const styles = theme => ({
  icon: {
    maxWidth: 20
  }
});

/**
 * Component displaying the admin-gear, with a
 * link to admin.
 */
class AdminLink extends React.Component {
  constructor() {
    super();

    this.state = {
      adm: false
    };
  }

  handleClick = url => {
    let cookie = Utility.getUserCookie();
    window.location.replace(url + "?u=" + cookie.uid);
  };

  render() {
    if (!this.state.adm) {
      return "";
    } else {
      return (
        <IconButton onClick={this.handleClick.bind(this, "/admin")} color="inherit" size="small" variant="flat">
          <SettingsIcon />
        </IconButton>
      );
    }
  }

  componentDidMount() {
    let userCookie = Utility.getUserCookie();
    if (userCookie) {
      this.setState({ adm: userCookie.adm });
    }
  }
}

export default withStyles(styles)(AdminLink);
