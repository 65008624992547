import React from "react";

import Paper from "@material-ui/core/Paper";

import Utility from "./utility";
import Calendar from "./calendar";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
    padding: "1.1%"
  },

  vSpacer: {
    marginTop: "65px",
    width: "100%"
  }
});

class User extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    let calendarId = this.getCalendarId();
    this.updateCalendar(calendarId);
  }

  updateCalendar(calendarId) {
    let self = this;

    Utility.apiRequest("calendar/" + calendarId, "GET", data => {
      self.setState({ data });
    });
  }

  getCalendarId() {
    return window.location.pathname.split("/")[2];
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.vSpacer}>
        <Paper className={classes.root} elevation={0}>
          <Calendar calendar={this.state.data}/>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(User);
