import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';

import red from '@material-ui/core/colors/red';
import pink from '@material-ui/core/colors/pink';
import blue from '@material-ui/core/colors/blue';
import lightBlue from '@material-ui/core/colors/lightBlue';
import cyan from '@material-ui/core/colors/cyan';
import teal from '@material-ui/core/colors/teal';
import green from '@material-ui/core/colors/green';
import lightGreen from '@material-ui/core/colors/lightGreen';
import lime from '@material-ui/core/colors/lime';
import yellow from '@material-ui/core/colors/yellow';
import amber from '@material-ui/core/colors/amber';
import orange from '@material-ui/core/colors/orange';
import deepOrange from '@material-ui/core/colors/deepOrange';
import grey from '@material-ui/core/colors/grey';

const styles = (theme) => ({
  root: {
    color: theme.palette.text.primary,
  },
  card: {
    backgroundColor: '#eee',
    marginBottom: 10,
  },
  button: {
    maxWidth: 40,
    maxHeight: 40,
    width: 40,
    height: 40,
    margin: theme.spacing.unit,
  },
  categoryWarningText: {
    marginTop: '5px',
  },
  catsquare: {
    width: 20,
    height: 20,
    display: 'block',
    borderRadius: 2,
    float: 'left',
    margin: '-2px 10px 0 0',
  },
});

let selectedCategory = {};

/**
 * Class containing methods used to render the categories
 * section in admin.
 */
class Categories extends React.Component {
  constructor() {
    super();

    this.state = {
      showCategoryDialog: false,
      showDeleteDialog: false,
      calendarId: '',
      oldItem: {},
      item: {
        id: '',
        title: '',
        description: '',
        color: '',
        global: false,
      },
    };
  }

  componentDidMount() {
    if (typeof (this.props.calendarId !== 'undefined' && this.props.calendarId !== '')) {
      this.setState({ calendarId: this.props.calendarId });
    }
  }

  /**
   * Handles title update.
   */
  onTitleUpdate = (e) => {
    if (typeof e.target !== 'undefined') {
      let itm = this.state.item;
      itm.title = e.target.value;
      this.setState({ item: itm });
    }
  };

  /**
   * Handles description update.
   */
  onDescriptionUpdate = (e) => {
    if (typeof e.target !== 'undefined') {
      let itm = this.state.item;
      itm.description = e.target.value;
      this.setState({ item: itm });
    }
  };

  /**
   * Opens the category dialog.
   */
  openCategoryDialog = (category) => {
    if (
      typeof category !== 'undefined' &&
      category !== null &&
      typeof category.id !== undefined &&
      category.id !== ''
    ) {
      selectedCategory = category;
      let oldItem = Object.assign(this.state.oldItem, category);
      this.setState({
        oldItem: oldItem,
        item: category,
        showCategoryDialog: true,
      });
    } else {
      selectedCategory = {};
      this.setState({
        item: {
          id: '',
          title: '',
          description: '',
          color: '',
          global: false,
        },
        showCategoryDialog: true,
      });
    }
  };

  /**
   * Cancels the category dialog.
   */
  cancelCategoryDialog = () => {
    selectedCategory = {};
    this.props.saveCategoryHandler(this.state.oldItem);
    this.setState({ showCategoryDialog: false });
  };

  /**
   * Handles the checked item.
   */
  handleChecked = (name) => (event) => {
    let itm = this.state.item;
    itm.global = event.target.checked;
    this.setState({ item: itm });
  };

  /**
   * Sets the selected color.
   */
  selectedColor = (e, color) => {
    let itm = this.state.item;
    itm.color = color[500];
    this.setState({ item: itm });
  };

  /**
   * Saves the created category.
   */
  saveCategory = () => {
    this.props.saveCategoryHandler(this.state.item);
    this.setState({ showCategoryDialog: false });
  };

  /**
   * Deletes the selected category.
   */
  deleteCategory = () => {
    this.props.deleteCategoryHandler(selectedCategory);
    this.cancelDeleteDialog();
  };

  /**
   * Opens the delete dialog.
   */
  openDeleteDialog = (category) => {
    selectedCategory = category;
    this.setState({
      item: category,
      showDeleteDialog: true,
    });
  };

  /**
   * Cancels the delete dialog.
   */
  cancelDeleteDialog = () => {
    this.setState({
      item: {
        id: '',
        title: '',
        description: '',
        color: '',
        global: false,
      },
      showDeleteDialog: false,
    });

    selectedCategory = {};
  };

  /**
   * Renders the category colors table.
   */
  renderColorTable() {
    const { classes } = this.props;

    let colors = [
      red,
      pink,
      blue,
      lightBlue,
      cyan,
      teal,
      green,
      lightGreen,
      lime,
      yellow,
      amber,
      orange,
      deepOrange,
      grey,
    ];

    return colors.map((color) => {
      return (
        <Button
          key={Math.random()}
          color="primary"
          className={classes.button}
          variant="contained"
          onClick={(e) => this.selectedColor(e, color)}
          style={{ backgroundColor: color[500] }}
        />
      );
    });
  }

  /**
   * Renders the selected category color.
   */
  renderSelectedColor() {
    const colorStyle = {
      backgroundColor: this.state.item.color,
    };
    return <span style={colorStyle}>&nbsp;</span>;
  }

  /**
   * Renders the coloured square next to the category title.
   * @param {obj} c
   */
  renderColorSquare(c) {
    const { classes } = this.props;
    let bgColor = typeof c.color === 'object' ? c.color.main : c.color;
    let style = { backgroundColor: bgColor };
    return <span className={classes.catsquare} style={style} />;
  }

  /***
   * If the category has been edited, it is stored
   * under the state.item property.
   */
  _isCategoryDirty(categoryId) {
    let item = this.state.item;
    if (item && item.id === categoryId) {
      return true;
    }
    return false;
  }

  /**
   * Renders the table containing the categories
   * and the list of colors used when registering a new category.
   */
  render() {
    const { classes } = this.props;
    const { title, description, color } = this.state.item;
    return (
      <div className="paperwrap">
        <div className="paperheadwrap">
          <h4 className="paperhead">Hendelseskategorier</h4>
          <Button
            classes={classes.button}
            color="primary"
            onClick={this.openCategoryDialog.bind(this, null)}
            variant="flat"
          >
            Legg til
          </Button>
        </div>
        <Table>
          <TableBody>
            {this.props.categories &&
              this.props.categories.length > 0 &&
              this.props.categories.map((c) => {
                if (this._isCategoryDirty(c.id)) {
                  c = this.state.item;
                }
                return (
                  <TableRow>
                    <TableCell>
                      {this.renderColorSquare(c)}
                      {c.title}
                    </TableCell>
                    <TableCell numeric="true">
                      <IconButton
                        classes={classes.button}
                        color="primary"
                        onClick={this.openCategoryDialog.bind(this, c)}
                      >
                        <EditIcon classes={classes.button} />
                      </IconButton>
                      <IconButton classes={classes.button} onClick={this.openDeleteDialog.bind(this, c)}>
                        <DeleteIcon classes={classes.button} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        {this._renderCategoriesWarning(classes)}

        <Dialog open={this.state.showCategoryDialog}>
          <DialogTitle>Hendelseskategori</DialogTitle>
          <DialogContent>
            <TextField
              id="item-title"
              type="string"
              defaultValue={title}
              placeholder="Navn"
              label="Navn"
              margin="normal"
              fullWidth
              autoFocus
              onChange={(e) => this.onTitleUpdate(e)}
            />

            <TextField
              id="item-description"
              type="string"
              defaultValue={description}
              placeholder="Beskrivelse"
              label="Beskrivelse"
              margin="normal"
              fullWidth
              onChange={(e) => this.onDescriptionUpdate(e)}
            />

            {color !== '' && (
              <div>
                Farge: <span style={{ backgroundColor: color }}>&nbsp;&nbsp;&nbsp;&nbsp;</span>
              </div>
            )}

            <div>{this.renderColorTable()}</div>
          </DialogContent>
          <DialogActions>
            <Button className={classes.button} onClick={this.cancelCategoryDialog} color="secondary">
              Avbryt
            </Button>
            <Button className={classes.button} onClick={(e) => this.saveCategory(e)} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.showDeleteDialog}>
          <DialogTitle>Slette</DialogTitle>
          <DialogContent>Slette valgt kategori?</DialogContent>
          <DialogActions>
            <Button className={classes.icon} onClick={(e) => this.cancelDeleteDialog(e)}>
              Avbryt
            </Button>
            <Button className={classes.icon} onClick={this.deleteCategory}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  /**
   * Renders the missing event categories warning, if necessary.
   * @param {*} classes
   */
  _renderCategoriesWarning(classes) {
    let selectedeventcategories = this.props.selectedeventcategories;
    if (typeof selectedeventcategories !== 'undefined' && selectedeventcategories.length === 0) {
      return <div className={classes.categoryWarningText}>Kalenderen må ha minst en hendelseskategori.</div>;
    } else {
      return '';
    }
  }
}

Categories.propTypes = {
  selectedeventcategories: PropTypes.object.isRequired,
  saveCategoryHandler: PropTypes.func.isRequired,
  categories: PropTypes.object.isRequired,
};

export default withStyles(styles)(Categories);
