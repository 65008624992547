import React from "react";
import Utility from "../../utility";

import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import CalendarTodayIcon from "@material-ui/icons/PermContactCalendar";

import { withStyles } from "@material-ui/core";

const styles = theme => ({
  icon: {
    marginRight: 10
  }
});

let userCookie = Utility.getUserCookie();

/**
 * Component listing calendars available to the user,
 * allowing the user to select a different calendar.
 */
class CalendarSwitcher extends React.Component {
  constructor() {
    super();

    this.state = {
      calendarId: null,
      links: [
        {
          id: "",
          title: ""
        }
      ],
      anchorEl: null
    };
  }

  componentDidMount() {
    Utility.apiRequest("calendar", "GET", data => {
      this._pushToState(data);
    });
  }

  render() {
    const { classes } = this.props;
    const { anchorEl } = this.state;

    if (this.state.links.length === 0) {
      return "";
    }

    let menuItems = this.state.links.map(l => {
      return (
        <MenuItem
          key={Math.random()}
          onClick={this._handleSelectedCalendar.bind(this, l.id)}
        >
          {l.title}
        </MenuItem>
      );
    });

    return (
      <div>
        <Button
          aria-owns={anchorEl ? "list-calendars" : null}
          aria-haspopup="true"
          onClick={this._handleClick}
          color="primary"
          size="small"
          className={classes.button}
        >
          <CalendarTodayIcon />
          Velg kalender
        </Button>
        <Menu
          id="list-calendars"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this._handleClose}
        >
          {menuItems}
        </Menu>
      </div>
    );
  }

  /**
   * Pushes the different calendar links to the state object
   * for later use.
   * @param {JSON} data
   */
  _pushToState(data) {
    let links = [];

    if (typeof userCookie !== "undefined" && userCookie.cids.length > 0) {
      for (let i = 0; i < data.length; i++) {
        if (userCookie.cids.indexOf(data[i].id) > -1) {
          links.push({ id: data[i].id, title: data[i].title });
        }
      }
    }

    this.setState({
      links: links
    });
  }

  _handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  _handleClose = () => {
    this.setState({ anchorEl: null });
  };

  _handleSelectedCalendar = id => {
    let url = "/calendar/" + id + "?u=" + userCookie.uid;
    window.location.replace(url);
  };
}

export default withStyles(styles)(CalendarSwitcher);
