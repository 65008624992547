import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import IconRefresh from "@material-ui/icons/Refresh";
import IconButton from "@material-ui/core/IconButton";

import Utility from "../../utility";

import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core";

const styles = theme => ({
  root: {
    color: theme.palette.text.primary
  },
  container: {
    marginLeft: 10,
    display: "inline"
  }
});

class MonthYearSelector extends React.Component {
  constructor() {
    super();

    this.monthNames = Utility.monthNames;

    this.state = {
      baseYear: 0,
      year: 0,
      monthIx: 1,
      monthName: 'Januar'
    };
  }

  componentDidMount() {
    this._readCookie();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props !== prevProps) {
      this._readCookie();
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        {this._renderMonthSelector(classes)}
        {this._renderYearSelector(classes)}
        <IconButton
          className={classes.button}
          variant="contained"
          size="small"
          color="primary"
          onClick={e => this._showCalendar(e)}
        >
          <IconRefresh size="small" />
        </IconButton>
      </div>
    );
  }

  _readCookie() {
    let periodCookie = Utility.getPeriodCookie();

    if (periodCookie !== null) {
      let periodStart = Utility.iso8601DateStrToDate(
          periodCookie.split("|")[0]
        ),
        year = periodStart.getFullYear(),
        month = periodStart.getMonth();

      if (this.state.year !== year && this.state.monthIx !== month) {
        this.setState({
          baseYear: year,
          year: year,
          monthIx: month,
          monthName: this.monthNames[month]
        });
      }
    }
  }

  _renderYearSelector(classes) {
    return (
      <FormControl className={classes.formControl}>
        <Select value={this.state.year} onChange={e => this._onYearChange(e)}>
          {this._renderYearItems()}
        </Select>
      </FormControl>
    );
  }

  _renderMonthSelector(classes) {
    return (
      <FormControl className={classes.formControl}>
        <Select
          value={this.state.monthName}
          onChange={e => this._onMonthChange(e)}
        >
          {this._renderMonthItems()}
        </Select>
      </FormControl>
    );
  }

  _renderYearItems() {
    let startYear = this.state.baseYear,
      selectedYear = this.state.year,
      max = 15,
      c = 0,
      items = [];

    while (c < max) {
      let cy = startYear + c;
      if (cy === selectedYear) {
        items.push(
          <MenuItem key={cy} value={cy} selected>
            {cy}
          </MenuItem>
        );
      } else {
        items.push(
          <MenuItem key={cy} value={cy}>
            {cy}
          </MenuItem>
        );
      }
      c++;
    }
    return items;
  }

  _renderMonthItems() {
    let items = [];
    for (let n = 0; n < this.monthNames.length; n++) {
      let monthName = this.monthNames[n];
      items.push(
        <MenuItem key={n} value={monthName}>
          {monthName}
        </MenuItem>
      );
    }
    return items;
  }

  _onMonthChange = e => {
    let ix = this.monthNames.findIndex(function(name) {
      return name === e.target.value;
    });

    this.setState({
      monthIx: ix,
      monthName: e.target.value
    });
  };

  _onYearChange = e => {
    this.setState({
      year: e.target.value
    });
  };

  _showCalendar = () => {
    let period = Utility.getMonthStartEnd(this.state.year, this.state.monthIx);
    Utility.setPeriodCookie(period.start + "|" + period.end);
    this.props.showCalendarHandler(period);
  };
}

MonthYearSelector.propTypes = {
  showCalendarHandler: PropTypes.func.isRequired
};

export default withStyles(styles)(MonthYearSelector);
