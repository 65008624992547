import React from "react";
import config from "../../../config";

import { withStyles } from "@material-ui/core";

const styles = theme => ({});

/**
 * Component displaying the footer navigation
 */
class FootNav extends React.Component {
  render() {
    return (
      <ul id="footnav">
        <li>
          <a href="#" onClick={evt => this._printPage(evt)}>
            <i className="fas fa-print" />
            Skriv ut
          </a>
        </li>
        {this.props.data !== null && typeof this.props.data.rssActive !== undefined && this.props.data.rssActive === true && (
          <li>
            <a href={config.exportUrls.rss + this.props.data.id}>
              <i className="fas fa-rss-square" />
              Eksporter til RSS
            </a>
          </li>
        )}
        {this.props.data !== null && typeof this.props.data.icsActive !== undefined && this.props.data.icsActive === true && (
          <li>
            <a href={config.exportUrls.ics + this.props.data.id}>
              <i className="far fa-calendar-alt" />
              Eksporter til ICS
            </a>
          </li>
        )}
      </ul>
    );
  }

  componentDidMount() {}

  _printPage(evt) {
    window.print();
    evt.preventDefault();
    return false;
  }
}

export default withStyles(styles)(FootNav);
