import React from "react";

import { withStyles } from "@material-ui/core";

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FieldsTable from "./fieldstable";

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    backgroundColor: "#eee"
  },
  card: {
    margin: "10px 0"
  },
  icon: {
    margin: theme.spacing.unit
  },
  button: {
    margin: theme.spacing.unit
  }
});

class EventFieldGroup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showEditFieldDialog: false,
      showNewForm: false,
      groupId: "",
      groupName: "",
      calendarId: "",
      fieldGroups: []
    };
  }

  componentDidMount() {
    let fieldGroups = this.props.fieldGroups;
    if (typeof fieldGroups === "undefined") {
      fieldGroups = [];
    }
    this.setState({ fieldGroups: fieldGroups });
  }

  handleChange = name => event => {
    this.setState({
      groupName: event.target.value
    });
  };

  handleBtnNewClick = () => {
    this.setState({ showNewForm: true });
  };

  handleBtnNewOkClick = () => {
    if (this.state.groupId === "") {
      // Creating a new field group
      this._pushGroupToStack();
    } else {
      // Updating existing field group
      let fieldGroups = this.state.fieldGroups;
      fieldGroups.find(fg => {
        if (fg.id === this.state.groupId) {
          fg.title = this.state.groupName;
        }
        return null;
      });
      this.setState({ fieldGroups: fieldGroups });
    }

    this.cancelNewForm();
    this.props.fieldGroupHandler(this.state.fieldGroups);
  };

  cancelNewForm = () => {
    this.setState({
      showNewForm: false,
      groupName: ""
    });
  };

  showNewForm = group => {
    this.setState({
      showNewForm: true,
      groupName: group["title"],
      groupId: group["id"]
    });
  };

  toggleActive = group => {
    let fieldGroups = this.state.fieldGroups;
    fieldGroups.find(fg => {
      if (fg.id === group.id) {
        if (typeof fg.active === "undefined") {
          fg.active = false;
        } else {
          fg.active = !fg.active;
        }
      }
      return null;
    });
    this.setState({ fieldGroups: fieldGroups });
  };

  delete = group => {
    let fieldGroups = this.state.fieldGroups;
    fieldGroups = fieldGroups.map(fg => {
      if (fg.id === group.id) {
        fg.deleted = true;
      }
      return fg;
    });
    this.setState({ fieldGroups: fieldGroups });
  };

  saveField = field => {
    // Lookup field group that will contain the specified field.
    let fieldGroups = this.state.fieldGroups,
      fieldGroup = null;

    if (typeof field.field_group_id !== "undefined") {
      fieldGroup = fieldGroups.find(fg => {
        return fg.id === field.field_group_id;
      });
    } else {
      fieldGroup = fieldGroups[field.ix];
    }

    // Lookup field, if it exists
    if (field.id === "") {
      // New field
      if (typeof fieldGroup.fields === "undefined") {
        fieldGroup.fields = [field];
      } else {
        fieldGroup.fields.push(field);
      }
    } else {
      let existingFieldIx = fieldGroup.fields.findIndex(f => {
        return f.id === field.id;
      });
      fieldGroup.fields[existingFieldIx] = field;
    }

    // Update calendar state
    this.props.saveFieldGroupsHandler(fieldGroups);
  };

  render() {
    const { fieldGroups } = this.state;

    return (
      <div className="paperwrap">
        <div className="paperheadwrap">
          <h4 className="paperhead">Egendefinerte feltfaner</h4>
          <Button color="primary" variant="text" onClick={this.handleBtnNewClick}>
            Legg til
          </Button>
        </div>
        {this.state.showNewForm && this._renderNewForm()}
        <div>{this._renderList(fieldGroups)}</div>
      </div>
    );
  }

  _renderNewForm() {
    const { classes } = this.props;

    return (
      <div>
        <Card className={classes.card}>
          <CardContent>
            <TextField id="name" label="Gruppenavn" className={classes.textField} value={this.state.groupName} onChange={this.handleChange("name")} autoFocus margin="normal" />
          </CardContent>
          <CardActions>
            <Button color="secondary" variant="flat" onClick={this.cancelNewForm}>
              Avbryt
            </Button>
            <Button color="primary" variant="flat" onClick={this.handleBtnNewOkClick.bind(this)}>
              Ok
            </Button>
          </CardActions>
        </Card>
      </div>
    );
  }

  _renderList(fieldGroups) {
    return fieldGroups.map((fg, ix) => {
      return this._renderFieldGroup(fg, ix);
    });
  }

  _renderFieldGroup(fg, ix) {
    const { classes } = this.props;
    return (
      <Card key={Math.random()} className={classes.card}>
        <CardContent>
          {fg.title} {(typeof(fg.active) !== 'undefined' && fg.active === false) && " (inaktiv)"}
          <FieldsTable fieldGroupId={fg.id} fieldGroupIx={ix} fieldGroups={this.state.fieldGroups} calendarId={this.state.calendarId} saveFieldHandler={this.saveField} />
        </CardContent>
        <CardActions>
          <Button color="primary" variant="text" onClick={this.showNewForm.bind(this, fg)}>
            Rediger
          </Button>
          <Button color="default" variant="text" onClick={this.toggleActive.bind(this, fg)}>
            {typeof fg.active === "undefined" || fg.active ? "Deaktiver" : "Aktiver"}
          </Button>
          {!fg.deleted && (
            <Button color="secondary" variant="text" onClick={this.delete.bind(this, fg)}>
              Slett
            </Button>
          )}
        </CardActions>
      </Card>
    );
  }

  _pushGroupToStack() {
    let fGroups = this.state.fieldGroups;
    fGroups.unshift({
      id: null,
      title: this.state.groupName,
      fields: []
    });
    this.setState({ fieldGroups: fGroups, groupName: "" });
  }
}

export default withStyles(styles)(EventFieldGroup);
