import config from "../config";

class AdminUtility {
  /**
   * Returns the userID.
   */
  static getUserCookie() {
    let result = null;
    if (document.cookie) {
      let cookies = document.cookie.split(";");
      cookies.forEach(c => {
        let parts = c.split("=");
        if (parts[0].toString().trim() === "u") {
          result = parts[1].toString().trim();
        }
      });
    }
    return JSON.parse(result === "" ? null : result);
  }

  static async apiRequest(url, method, callback, body) {
    let API_URL = config.adminUrl,
      cookie = AdminUtility.getUserCookie(),
      COYO_USER_ID = cookie.uid;

    if (method !== "GET") {
      if (typeof body === "undefined" || body === null) {
        body = JSON.stringify({ userId: COYO_USER_ID });
      } else {
        body.userId = COYO_USER_ID;
        body = JSON.stringify(body);
      }
    }

    let apiUrl = API_URL + url;

    await fetch(apiUrl, {
      method: method,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
        Accept: "application/json",
        "Content-Type": "application/json",
        "Coyo-User": COYO_USER_ID
      },
      body: body
    })
      .then(response => {
        if (!response.ok) {
          if (response.status === 401) {
            throw Error("Ingen tilgang, vennligst ta kontakt med din systemadministrator.");
          } else {
            throw Error("Det har oppstått en ukjent feil, vennligst ta kontakt med din systemadministrator.");
          }
        }
        return response.json();
      })
      .then(data => {
        if (data.status === "success" && data.data) {
          callback(data.data);
        } else if (data.status === "success") {
          callback(data);
        } else if (data.status === "error") {
          if (data.message) throw Error(data.message);
        }
      })
      .catch(err => {
        // An exception occured, redirecting to error page.
        window.location.replace("/error/?msg=" + err);
        //throw err;
      });
  }

  /**
   * Create UUID
   */
  static createUUID() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16));
  }

  /**
   * Converts an input type to a human readable string.
   * @param {int} inputType
   */
  static inputTypeToHr(inputType) {
    switch (inputType) {
      case 10:
        return "string";
      case 20:
        return "integer";
      case 30:
        return "decimal";
      case 40:
        return "timestamp";
      case 50:
        return "time";
      case 60:
        return "boolean";
      default:
        return "string";
    }
  }

  /**
   * Converts a field type to a human readable string.
   * @param {int} fieldType
   */
  static fieldTypeToHr(fieldType) {
    switch (fieldType) {
      case 10:
        return "input";
      case 20:
        return "select";
      case 30:
        return "multiple select";
      default:
        return "input";
    }
  }

  static fieldTypeHrToInt(fieldType) {
    switch (fieldType) {
      case "input":
        return 10;
      case "select":
        return 20;
      case "multiple select":
        return 30;
      default:
        return 10;
    }
  }

  static inputTypeHrToInt(inputType) {
    switch (inputType) {
      case "string":
        return 10;
      case "integer":
        return 20;
      case "decimal":
        return 30;
      case "timestamp":
        return 40;
      case "time":
        return 50;
      case "boolean":
        return 60;
      default:
        return 10;
    }
  }
}

export default AdminUtility;
