import React from "react";

import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import { withStyles } from "@material-ui/core";

const styles = theme => ({
  root: {
    color: theme.palette.text.primary
  },
  icon: {
    margin: theme.spacing.unit
  },
  button: {
    margin: theme.spacing.unit
  }
});

/**
 * Component used to display the dialog, which asks the user for
 * confirmation before deleting an item.
 */
class DeleteItem extends React.Component {
  constructor() {
    super();

    this.state = {
      itemId: ""
    };
  }

  handleOnEnter = () => {
    this.setState({ itemId: this.props.itemId });
  };

  cancelDialog = () => {
    this.props.onCancel();
  };

  deleteItem = () => {
    this.props.deleteItemHandler(this.props.itemId);
  };

  render() {
    const { classes, open, onCancel, itemId, ...other } = this.props;

    return (
      <Dialog
        open={open || false}
        onCancel={e => this.cancelDialog(e)}
        onEnter={this.handleOnEnter}
        disableBackdropClick
        aria-labelledby="deleteitem-dialog-title"
        {...other}
      >
        <DialogTitle id="deleteitem-dialog-title">{"Slette"}</DialogTitle>
        <DialogContent>Slette valgt kalender?</DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            className={classes.button}
            onClick={e => this.cancelDialog(e)}
          >
            Avbryt
          </Button>
          <Button
            color="primary"
            className={classes.button}
            onClick={e => this.deleteItem(e)}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

DeleteItem.propTypes = {
  itemId: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  deleteItemHandler: PropTypes.func.isRequired
};

export default withStyles(styles)(DeleteItem);
