import React from "react";

import Utility from "../../utility";

/**
 * Component used to display a preview of the
 * calendar event when hovering over it.
 */
class PreviewPopUp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedEvent: this.props.selectedevent,
      anchorEl: this.props.anchorel
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.selectedEvent !== nextProps.selectedevent && nextState.anchorEl !== nextProps.anchorel) {
      this.setState({
        selectedEvent: nextProps.selectedevent,
        anchorEl: nextProps.anchorel
      });
    }
    this.render();
  }

  render() {
    const { selectedEvent, anchorEl } = this.state;

    if (!selectedEvent || !anchorEl) {
      return null;
    }

    let previewWidth = 460,
      elementPos = this._getCumulativeOffset(anchorEl),
      yPos = elementPos.top,
      xPos = elementPos.left;

    if (xPos > window.innerWidth / 2) {
      xPos = xPos - previewWidth - 10;
    } else {
      xPos = xPos + anchorEl.offsetWidth + 10;
    }

    let categoryColor = this._getCategoryColor(selectedEvent);
    
    return (
      <div className="previewpopup" style={{left: xPos, top: yPos, width: previewWidth}}>
        <div className="head">{selectedEvent.title}</div>
        <table>
          <tbody>
            <tr key="tr-start">
              <th>Start</th>
              <td>
                {Utility.convertEventDateTimeToDate(selectedEvent.event_start) + ' ' + Utility.convertEventDateTimeToTime(selectedEvent.event_start)}
              </td>
            </tr>
            <tr key="tr-end">
              <th>Slutt</th>
              <td>
                {Utility.convertEventDateTimeToDate(selectedEvent.event_end) + ' ' + Utility.convertEventDateTimeToTime(selectedEvent.event_end)}
              </td>
            </tr>
            <tr key="tr-cat">
              <th>Kategori</th>
              <td>
                <span className="catcolor" style={{ backgroundColor: categoryColor }} />
                {this._getCategoryName(selectedEvent)}
              </td>
            </tr>
            {this._renderDescription(selectedEvent)}
            {this._renderAdditionalFields(selectedEvent.fields)}
          </tbody>
        </table>
      </div>
    );
  }

  _renderDescription(selectedEvent) {
    if (selectedEvent.description && selectedEvent.description.length > 0) {
      return (
        <tr key="tr-desc">
          <th>Beskrivelse</th>
          <td>{selectedEvent.description}</td>
        </tr>
      );
    } else {
      return null;
    }
  }

  _getCategoryColor(evt) {
    let categoryColor = "#aaaaaa";
    if (typeof evt.event_category_id !== "undefined" && evt.event_category_id !== null) {
      let color = Utility.getCategoryColorFromId(evt.event_category_id, this.props.categories);
      if (color !== null) {
        categoryColor = color;
      }
    }
    return categoryColor;
  }

  _getCategoryName(evt) {
    if (typeof evt.event_category_id !== "undefined" && evt.event_category_id !== null) {
      let cat = this.props.categories.find(c => {
        return c.id === evt.event_category_id;
      });
      return typeof cat !== "undefined" ? cat.title : "Ukategorisert";
    } else {
      return "Ukategorisert";
    }
  }

  _getCumulativeOffset = function(element) {
    var top = 0,
      left = 0;
    do {
      top += element.offsetTop || 0;
      left += element.offsetLeft || 0;
      element = element.offsetParent;
    } while (element);

    return {
      top: top,
      left: left
    };
  };

  _renderAdditionalFields(fields) {
    if (!fields || fields.length === 0) {
      return null;
    }
    try {
      fields.map(f => {
        return this._renderField(f);
      });
      return null;

    } catch (e) {
      return null;
      //console.log("Error");
    }
  }

  _renderField(field) {
    if (typeof field.values === "undefined" || field.values === "" || (typeof field.values === "object" && typeof field.values[0] === "undefined")) {
      return null;
    } else {
      return (
        <tr key={Math.random()}>
          <th>{field.label}</th>
          <td className={Utility.getEventStatusFromField(field)}>
            {field.values.map(fv => {
              return " " + Utility.stripBracketsFromString(fv.value);
            })}
          </td>
        </tr>
      );
    }
  }
}

export default PreviewPopUp;
