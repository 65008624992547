import React from "react";

import { withStyles } from "@material-ui/core";
import Utility from "../../utility";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import Button from "@material-ui/core/Button";
import CancelIcon from "@material-ui/icons/Cancel";
import PrintIcon from "@material-ui/icons/Print";

const styles = theme => ({
  root: {
    color: theme.palette.text.primary
  },
  icon: {
    margin: theme.spacing.unit
  },
  button: {
    margin: theme.spacing.unit
  }
});

/**
 * Component used to display a preview of the
 * calendar event when hovering over it.
 */
class PrintEventDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedEvent: this.props.selectedevent
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.selectedEvent !== nextProps.selectedevent) {
      this.setState({
        selectedEvent: nextProps.selectedevent
      });
    }
    this.render();
  }

  render() {
    const { selectedEvent } = this.state;
    const { classes, ...other } = this.props;

    if (!selectedEvent) {
      return "";
    }

    let categoryColor = this._getCategoryColor(selectedEvent);

    return (
      <Dialog
        open={false}
        onCancel={this._cancelDialog}
        aria-labelledby="add-dialog-title"
        style={{
          margin: "10px auto",
          maxWidth: "900px",
          borderRadius: "5px"
        }}
        scroll="paper"
        fullScreen
        {...other}
      >
        <DialogTitle id="add-dialog-title" style={{ padding: "20px" }}>
          {"Forhåndsvisning"}
        </DialogTitle>
        <DialogContent>
          <div className="print" id="printArea">
            <h2>{selectedEvent.title}</h2>
            <table
              style={{
                width: "100%",
                border: "1px solid #ddd",
                borderRadius: "5px"
              }}
            >
              <tbody>
                <tr key="print-start">
                  <th>Start</th>
                  <td>
                    {Utility.convertEventDateTimeToDate(
                      selectedEvent.event_start
                    )}{" "}
                    {Utility.convertEventDateTimeToTime(
                      selectedEvent.event_start
                    )}
                  </td>
                </tr>
                <tr key="print-end">
                  <th>Slutt</th>
                  <td>
                    {Utility.convertEventDateTimeToDate(
                      selectedEvent.event_end
                    )}{" "}
                    {Utility.convertEventDateTimeToTime(
                      selectedEvent.event_end
                    )}
                  </td>
                </tr>
                <tr key="print-cat">
                  <th>Kategori</th>
                  <td>
                    <span
                      className="catcolor"
                      style={{
                        backgroundColor: categoryColor,
                        borderColor: categoryColor
                      }}
                    />
                    {this._getCategoryName(selectedEvent)}
                  </td>
                </tr>
                {selectedEvent.description &&
                  selectedEvent.description.length > 0 && (
                    <tr key="print-desc">
                      <th>Beskrivelse</th>
                      <td style={{ whiteSpace: "pre-wrap" }}>
                        {selectedEvent.description}
                      </td>
                    </tr>
                  )}
                {this._renderAdditionalFields(selectedEvent.fields)}
              </tbody>
            </table>
          </div>
        </DialogContent>
        {this._renderDialogActions()}
      </Dialog>
    );
  }

  _getCategoryColor(evt) {
    let categoryColor = "#aaaaaa";
    if (
      typeof evt.event_category_id !== "undefined" &&
      evt.event_category_id !== null
    ) {
      let color = Utility.getCategoryColorFromId(
        evt.event_category_id,
        this.props.categories
      );
      if (color !== null) {
        categoryColor = color;
      }
    }
    return categoryColor;
  }

  _getCategoryName(evt) {
    if (
      typeof evt.event_category_id !== "undefined" &&
      evt.event_category_id !== null
    ) {
      let cat = this.props.categories.find(c => {
        return c.id === evt.event_category_id;
      });
      return typeof cat !== "undefined" ? cat.title : "Ukategorisert";
    } else {
      return "Ukategorisert";
    }
  }

  _getCumulativeOffset = function(element) {
    var top = 0,
      left = 0;
    do {
      top += element.offsetTop || 0;
      left += element.offsetLeft || 0;
      element = element.offsetParent;
    } while (element);

    return {
      top: top,
      left: left
    };
  };

  _renderAdditionalFields(fields) {
    try {
      return fields.map(f => {
        return this._renderField(f);
      });
    } catch (e) {
      return "";
    }
  }

  _renderField(field) {
    if (
      typeof field.values === "undefined" ||
      field.values === "" ||
      (typeof field.values === "object" &&
        typeof field.values[0] === "undefined")
    ) {
      return "";
    } else {
      return (
        <tr key={Math.random()}>
          <th>{field.label}</th>
          <td style={{ whiteSpace: "pre-wrap" }}>
            {field.values.map(fv => {
              return Utility.stripBracketsFromString(fv.value) + " ";
            })}
          </td>
        </tr>
      );
    }
  }

  /**
   * Renders the dialog actions
   * - close
   */
  _renderDialogActions() {
    const { classes } = this.props;
    return (
      <DialogActions>
        <Button
          size="small"
          color="primary"
          className={classes.button}
          onClick={e => this._executePrint(e)}
        >
          <PrintIcon className={classes.icon} />
          Skriv ut
        </Button>
        <Button
          size="small"
          color="secondary"
          className={classes.button}
          onClick={e => this._cancelDialog(e)}
        >
          <CancelIcon className={classes.icon} />
          Lukk
        </Button>
      </DialogActions>
    );
  }

  /**
   * Cancels and hides the dialog.
   */
  _cancelDialog = () => {
    this.props.onCancel();
  };

  /**
   * Executes printing of the dialog
   */
  _executePrint = () => {
    let prtContent = document.getElementById("printArea");
    let winPrint = window.open(
      "",
      "Utskrift",
      "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
    );
    let printHeader =
      '<html><head><link rel="stylesheet" type="text/css" href="/css/print.css"></head><body><div class="print">';
    let printFooter = "</div></body></html>";
    winPrint.document.write(printHeader + prtContent.innerHTML + printFooter);
    winPrint.document.close();
    setTimeout(function() {
      winPrint.focus();
      winPrint.print();
      winPrint.close();
    }, 1000);
  };
}

export default withStyles(styles)(PrintEventDetails);
