import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import AdminUtility from '../../../utility';

import { withStyles, TextField } from '@material-ui/core';

const styles = theme => ({
    root: {
      color: theme.palette.text.primary,
    },
    icon: {
      margin: theme.spacing.unit,
    },
    button: {
        margin:theme.spacing.unit,
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },
});

/**
 * Component used when displaying the dialog that allows
 * the user to administrate the fields assigned to a 
 * calendar.
 */
class EditFieldDialog extends React.Component {

    constructor() {
        super();
       
        this.state = {
            showDeleteDialog: false,
            item:  {
                calendar_id: '',
                id: '',
                field_label: '',
                description: '',
                tooltip: '',
                active: true,
                updated: '',
                field_type: 'input',
                input_type: 'string',
                listValues: [],
            }
        };
    }

    componentDidMount() {
        let item = this.state.item;
        item.calendar_id = this.props.item.calendar_id;

        if (typeof(this.props.item.id) !== 'undefined' && this.state.item.id === '') {
            // If a field has been selected for editing, update the state.
            item = this.props.item;
        } 
        this.setState({ item: item, });
    }

    saveItem = () => {
        let item = this.state.item;
        item.field_type = AdminUtility.fieldTypeHrToInt(item.field_type);
        item.input_type = AdminUtility.inputTypeHrToInt(item.input_type);
        this.props.saveFieldHandler(item);
        this.props.cancelDialogHandler();
    };

    pushToItemState(key, value) {
        var o = this.state.item;
        o[key] = value;
        this.setState({ item: o });
    };
    
    handleSelectChange = event => {
        this.pushToItemState(event.target.name, event.target.value);
    
        if (event.target.value === 'input') {
            this.clearListValues();
        }
    };

    handleTextChange = event => {
        this.pushToItemState(event.target.name, event.target.value);
    };

    addListValue = () => {
        let elInput = document.getElementById('field-value'),
            val = elInput.value,
            listValues = this.state.item.listValues;

        listValues.push(val);

        this.setState({ listValues: listValues, });

        elInput.value = '';
        elInput.focus();
    };

    clearListValues = () => {
        let item = this.state.item;
        item.listValues = [];
        this.setState({ item: item });
    };

    renderListValues() {
        return (
            <Table>
                { 
                this.state.item.listValues.map(el => {
                    let val = '';
                    if (typeof(el) === 'string') {
                        val = el;
                    } else {
                        if (el.string_value !== null) val = el.string_value;
                        if (el.integer_value !== null) val = el.integer_value;
                        if (el.decimal_value !== null) val = el.decimal_value;
                        if (el.boolean_value !== null) val = el.boolean_value;
                        if (el.time_value !== null) val = el.time_value;
                        if (el.timestamp_value !== null) val = el.timestamp_value;
                    }

                    return (
                        <TableRow>
                            <TableCell>{val}</TableCell>
                        </TableRow>
                    ) 
                })
                }
            </Table>
        )
    }

    renderAddFieldValue(classes) {
        return (
            <div>
                <div> 
                <FormControl className={classes.formControl}>
                        <TextField
                            id="field-value"
                            type="string"
                            placeholder="list value"
                            label="List value"
                            margin="normal"
                            fullWidth/>
                </FormControl>
                <Button 
                    className={classes.button} 
                    color="primary" 
                    variant="contained" 
                    size="small"
                    onClick={this.addListValue}>
                        Legg til
                </Button>
                </div>
                <div>
                    {this.state.item.listValues && this.state.item.listValues.length > 0 && this.renderListValues()}
                </div>
            </div>
        );
    }
   
    render() {
        const { classes, ...other } = this.props;
        return ( 
            <Dialog 
                open={false}
                onCancel={this.cancelDialog} 
                disableBackdropClick 
                aria-labelledby="editfield-dialog-title" 
                {...other}>
                <DialogTitle id="editfield-dialog-title">{"Legg til felt"}</DialogTitle>
                <DialogContent>     
                    
                    <TextField 
                        id="itemTitle" 
                        type="string" 
                        placeholder="Navn"
                        label="Navn"
                        margin="normal"
                        defaultValue={this.state.item.field_label}
                        onChange={this.handleTextChange}
                        inputProps={{
                            name:'field_label',
                            id:'itemTitle'
                        }}
                        fullWidth
                        autoFocus
                    />

                    <TextField
                        id="itemDescription"
                        type="string"
                        defaultValue={this.state.item.description}
                        placeholder="Beskrivelse"
                        label="Beskrivelse"
                        margin="normal"
                        onChange={this.handleTextChange}
                        inputProps={{
                            name:'description',
                            id:'itemDescription'
                        }}
                        fullWidth
                        multiline
                        rows="4"
                        rowsMax="12"
                    />

                    <TextField
                        id="itemTooltip"
                        type="string"
                        defaultValue={this.state.item.tooltip}
                        placeholder="Tooltip"
                        label="Tooltip"
                        margin="normal"
                        onChange={this.handleTextChange}
                        inputProps={{
                            name:'tooltip',
                            id:'itemTooltip'
                        }}
                        fullWidth
                        multiline
                        rows="2"
                        rowsMax="2"
                    />

                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="input_type">Datatype</InputLabel>
                        <Select
                            onChange={this.handleSelectChange}  
                            value={this.state.item.input_type}
                            inputProps={{
                                name:'input_type',
                                id:'input_type'
                            }}>
                            <MenuItem value="string">String</MenuItem>
                            <MenuItem value="integer">Integer</MenuItem>
                            <MenuItem value="boolean">Boolean</MenuItem>
                            <MenuItem value="date">Date</MenuItem>
                            <MenuItem value="time">Time</MenuItem>
                            <MenuItem value="datetime">Date/Time</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="field_type">Felttype</InputLabel>
                        <Select
                            onChange={this.handleSelectChange}                        
                            value={this.state.item.field_type}
                            inputProps={{
                                name:'field_type',
                                id:'field_type'
                            }}>
                            <MenuItem value="input">Tekst</MenuItem>
                            <MenuItem value="select">Valg</MenuItem>
                            <MenuItem value="multiple select">Flervalg</MenuItem>
                        </Select>
                    </FormControl>

                    { (this.state.item.field_type === 'select' || this.state.item.field_type === 'multiple select') && this.renderAddFieldValue(classes) }

                </DialogContent>
                <DialogActions>
                    <Button color="secondary" className={classes.button} onClick={(e) => this.props.cancelDialogHandler(e)}>Avbryt</Button>
                    <Button color="primary" className={classes.button} onClick={(e) => this.saveItem(e)}>Ok</Button>
                </DialogActions>
            </Dialog>
        );
    }
}

EditFieldDialog.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(EditFieldDialog)