import React from "react";

import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Typography from "@material-ui/core/Typography";

import MonthYearSelector from "../monthyearselector";
import CalendarSwitcher from "../calendarswitcher";
import AdminLink from "../adminlink";

const styles = theme => ({
  list: {
    width: 250
  },
  fullList: {
    width: "auto"
  },
  title: {
    marginTop: 16,
    marginLeft: 20
  },
  iconSmall: {
    maxWidth: 20
  },
  listItemsContainer: {
    marginLeft: 10,
    marginRight: "auto",
    width: 260
  }
});

/**
 * Component used to render the left drawer/left hand menu.
 */
class LeftDrawer extends React.Component {
  state = {
    left: false
  };

  componentDidMount() {
    this.props.onRef(this);
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open
    });
  };

  toggle = () => {
    this.setState({
      left: !this.state.left
    });
  };

  close = () => {
    this.setState({
      left: false
    });
  };

  _renderBackButton = classes => {
    return (
      <IconButton variant="contained" onClick={this.toggleDrawer("left", false)} onKeyDown={this.toggleDrawer("left", false)} size="small" color="primary" className={classes.button}>
        <ExitToAppIcon className={classes.iconSmall} />
      </IconButton>
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <Drawer open={this.state.left} onClose={this.toggleDrawer("left", false)}>
        <Typography variant="title" gutterBottom className={classes.title}>
          Innstillinger
          <AdminLink />
          {this._renderBackButton(classes)}
        </Typography>

        <div className={classes.listItemsContainer}>
          <MonthYearSelector showCalendarHandler={e => this.props.showCalendarCallback(e)} />
          {/*<CalendarSwitcher calendarId={this.props.calendarId} />*/}
        </div>
      </Drawer>
    );
  }
}

LeftDrawer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(LeftDrawer);
