import React from "react";

import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import AdminUtility from "../../../utility";
import Categories from "../../../categories";
import GroupSelector from "./groupselector";
import EventFieldGroup from "../eventfieldgroup";
import BookingOptions from "./bookingoptions";

import { withStyles } from "@material-ui/core";

const styles = theme => ({
  root: {
    color: theme.palette.text.primary
  },
  icon: {
    margin: theme.spacing.unit
  },
  button: {
    margin: theme.spacing.unit
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    backgroundColor: "#eee",
    marginTop: "10px"
  },
  card: {
    marginBottom: "10px"
  }
});

let emptyItem = {
  id: "",
  title: "",
  description: "",
  active: true,
  eventCategories: [],
  fieldGroups: [],
  rssActive: true,
  icsActive: true,
  booking: {
    confirmations: false,
    reminders: false,
    confirmationText: "",
    reminderSettings: ""
  }
};

/**
 * Dialog displayed when the user is attempting to administrate/update a calendar.
 */
class EditCalendarDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showEditFieldDialog: false,
      item: emptyItem,
      value: 0,
      mainTabIx: 0
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.open !== nextProps.open) {
      this.setState({ mainTabIx: 0 });
    }
  }

  handleFieldGroups = fieldGroups => {
    let item = this.state.item;
    item.fieldGroups = fieldGroups;
    this.setState({ item: item });
  };

  cancelDialog = () => {
    this.props.onCancel();
  };

  /**
   * Saves the updated item data, via the specified updateItemHandler().
   */
  saveItem = () => {
    if (this.state.item.eventCategories.length === 0) {
      alert("En kalender må ha minst en hendelseskategori.");
      return;
    }
    let item = Object.assign({}, this.state.item);
    this.props.updateItemHandler(item);
    this.cancelDialog();
  };

  /**
   * Handles the selected groups that will have access to this calendar.
   */
  selectedGroupHandler = groups => {
    let itm = this.state.item;
    itm.groups = groups;
    this.setState({ item: itm });
  };

  /**
   * Handles the booking options
   */
  bookingOptionsHandler = options => {
    let itm = this.state.item;
    itm.booking = options;
    this.setState({ item: itm });
  };

  /**
   * Track changes in active state.
   */
  onToggleActive = () => {
    let itm = this.state.item;
    itm.active = !itm.active;
    this.setState(itm);
  };

  /**
   * Track changes in RSS state.
   */
  onToggleRSSActive = () => {
    let itm = this.state.item;
    itm.rssActive = !itm.rssActive;
    this.setState(itm);
  };

  /**
   * Track changes in RSS state.
   */
  onToggleICSActive = () => {
    let itm = this.state.item;
    itm.icsActive = !itm.icsActive;
    this.setState(itm);
  };

  /**
   * Track title changes.
   */
  onTitleUpdate = e => {
    if (typeof e.target !== "undefined") {
      let itm = this.state.item;
      itm.title = e.target.value;
      this.setState({ item: itm });
    }
  };

  /**
   * Track description changes.
   */
  onDescriptionUpdate = e => {
    if (typeof e.target !== "undefined") {
      let itm = this.state.item;
      itm.description = e.target.value;
      this.setState({ item: itm });
    }
  };

  /**
   * Track changes in booking confirmation toggle
   */
  onToggleBookingConfirmations = () => {
    let itm = this.state.item;
    itm.booking.confirmations = !itm.booking.confirmations;
    this.setState({ item: itm });
  };

  /**
   * Track changes in booking toggle
   */
  onToggleBookingReminders = () => {
    let itm = this.state.item;
    itm.booking.reminders = !itm.booking.reminders;
    this.setState({ item: itm });
  };

  /**
   * Switches to the selected main tab:
   */
  handleMainTabChange = (event, mainTabIx) => {
    this.setState({ mainTabIx });
    var els = document.querySelectorAll(".tabcontainer");
    for (var i = 0; i < els.length; i++) {
      els[i].classList.remove("active");
    }
    document.getElementById("tabcontent" + mainTabIx).classList.add("active");
  };

  /**
   * Render the toggle active/inactive switch.
   */
  renderToggleActiveButton = () => {
    return (
      <FormGroup row>
        <FormControlLabel control={<Switch value="Active" defaultChecked={this.state.item.active} onChange={this.onToggleActive} color="primary" />} label="Publisert" />
      </FormGroup>
    );
  };

  /**
   * Closes the edit field dialog.
   */
  cancelFieldDialog = e => {
    this.setState({ showEditFieldDialog: false });
  };

  /**
   * Saves the specified field by updating the field
   * groups and their fields.
   */
  saveField = fieldGroups => {
    let item = this.state.item;
    item.fieldGroups = fieldGroups;
    this.setState({ item: item });
  };

  /**
   * Saves the specified category.
   */
  saveCategory = category => {
    if (typeof(category.id) === 'undefined') {
      return;
    }

    let item = this.state.item,
      eventCategories = item.eventCategories;

    let existingCategoryPos = eventCategories.findIndex(f => {
      return f.id === category.id;
    });

    if (existingCategoryPos === -1 || category.id === "") {
      eventCategories.push(category);
    } else {
      eventCategories[existingCategoryPos] = category;
    }

    this.setState({ item: item });
  };

  /**
   * Deletes the specified category.
   */
  deleteCategory = category => {
    let item = this.state.item;

    AdminUtility.apiRequest('eventcategory/' + category.id, 'DELETE', res => {
      let data = res.data;

      if (data === null && res.status === 'success') {
        // The category was deleted, keep state in sync!
        let eventCategories = item.eventCategories;

        let ecRemaining = eventCategories.filter(el => {
          return el.id !== category.id;
        });

        if (ecRemaining.length > 0) {
          item.eventCategories = ecRemaining;
        } else {
          item.eventCategories = [];
        }

        this.setState({ item: item });
      } else {
        console.error('An error occured when deleting the category: ' + category.id);
      }
    });
  };

  mapFieldType(val) {
    /*    
        INPUT = 10
        SELECT = 20
        MULTIPLE_SELECT = 30
        */
    switch (val) {
      case 10:
        return "input";
      case 20:
        return "select";
      case 30:
        return "multiple select";
      default:
        break;
    }
  }

  mapInputType(val) {
    /*
        STRING = 10
        INTEGER = 20
        DECIMAL = 30
        TIMESTAMP = 40
        TIME = 50
        BOOLEAN = 60
        */

    switch (val) {
      case 10:
        return "string";
      case 20:
        return "integer";
      case 30:
        return "decimal";
      case 40:
        return "timestamp";
      case 50:
        return "time";
      case 60:
        return "boolean";
      default:
        break;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // Makes sure the contents of the edit-calendar form
    // is cleared when an edit-operation is being cancelled and
    // that data is loaded correctly if the user has chosen to edit
    // an existing calendar.
    if (typeof(prevProps.item) !== 'undefined' 
      && typeof(prevProps.item.id) !== 'undefined' 
      && prevProps.item.id !== prevState.item.id) 
    {
      this.loadCalendarData();
    } else if (this.props.item === {}) {
      this.setState({item: emptyItem});
    }
  }

  /**
   * Loads data for the selected calendar.
   */
  loadCalendarData() {
    let self = this;
    AdminUtility.apiRequest("calendar/" + this.props.item.id, "GET", data => {
      if (typeof data.eventCategories === "undefined" || data.eventCategories === null) {
        data.eventCategories = [];
      }

      if (typeof data.groups !== "undefined" && data.groups.length > 0) {
        let groups = data.groups.map(g => {
          return g.group_id;
        });
        data.selectedGroups = groups;
      }

      if (typeof data.fieldGroups === "undefined" || data.fieldGroups === null) {
        data.fieldGroups = [];
      }

      self.setState({ item: data });
    });
  }

  /**
   * Renders the list of event categories.
   */
  renderCategories = (e, calendarId, eventCategories) => {
    return <Categories 
              categories={eventCategories} 
              calendarId={calendarId} 
              selectedeventcategories={this.state.item.eventCategories} 
              saveCategoryHandler={this.saveCategory.bind(this)} 
              deleteCategoryHandler={this.deleteCategory.bind(this)} />;
  };

  renderFeedOptions = () => {
    return (
      <div className="paperwrap">
        <div className="paperheadwrap">
          <h4 className="paperhead">Feeds</h4>
        </div>
        <div>
          <FormGroup row>
            <FormControlLabel control={<Switch value="Active" defaultChecked={this.state.item.rssActive} onChange={this.onToggleRSSActive} color="primary" />} label="Aktiver RSS-feed" />
            <FormControlLabel control={<Switch value="Active" defaultChecked={this.state.item.icsActive} onChange={this.onToggleICSActive} color="primary" />} label="Aktiver ICS-feed" />
          </FormGroup>
        </div>
      </div>
    );
  };

  renderBookingOptions = () => {
    return (
      <div className="paperwrap">
        <div className="paperheadwrap">
          <h4 className="paperhead">Bookingfunksjoner</h4>
        </div>
        <div>
          <FormGroup row>
            <FormControlLabel control={<Switch value="Active" defaultChecked={this.state.item.booking.confirmations} onChange={this.onToggleBookingConfirmations} color="primary" />} label="Aktiver epostbekreftelser" />
            <FormControlLabel control={<Switch value="Active" defaultChecked={this.state.item.booking.reminders} onChange={this.onToggleBookingReminders} color="primary" />} label="Aktiver epostpåminnelser" />
          </FormGroup>
        </div>
      </div>
    );
  };

  /**
   * Renders the tab definition:
   */
  renderTabs() {
    const { mainTabIx } = this.state;
    const { classes } = this.props;

    return (
      <Tabs value={mainTabIx} onChange={this.handleMainTabChange} className="tabWrapper">
        <Tab className={classes.tab + " tab"} label="Generelt" key={Math.random()} scrollable="true" />
        >
        <Tab className={classes.tab + " tab"} label="Kategorier" key={Math.random()} scrollable="true" />
        >
        <Tab className={classes.tab + " tab"} label="Feltfaner" key={Math.random()} scrollable="true" />>
        {this.state.item.booking && (this.state.item.booking.confirmations || this.state.item.booking.reminders) && <Tab className={classes.tab + " tab"} label="Booking" key={Math.random()} scrollable="true" />}
      </Tabs>
    );
  }

  /**
   * Render dialog contents.
   */
  render = () => {
    const { classes, open, onCancel, ...other } = this.props;
    const { item } = this.state;
    const { id, title, description, updated, eventCategories, fieldGroups, booking } = item;

    return (
      <Dialog
        open={open || false}
        onCancel={this.cancelDialog}
        disableBackdropClick
        aria-labelledby="edititem-dialog-title"
        style={{
          margin: "10px auto",
          maxWidth: "900px",
          borderRadius: "5px"
        }}
        scroll="paper"
        fullScreen
        {...other}>
        <DialogTitle id="edititem-dialog-title">{"Administrere kalender"}</DialogTitle>
        <DialogContent>
          {this.renderTabs()}
          <div className="tabcontainer active" id={"tabcontent0"} key="tabcontent0">
            <div className="paperwrap">
              <div className="paperheadwrap">
                <h4 className="paperhead">Generelle innstillinger</h4>
              </div>
              <div>
                <TextField id="item-title" type="string" defaultValue={title} placeholder="Navn" label="Navn" margin="normal" fullWidth autoFocus onChange={e => this.onTitleUpdate(e)} />

                <TextField id="item-description" defaultValue={description} placeholder="Beskrivelse" label="Beskrivelse" margin="normal" fullWidth multiline rowsMax="6" onChange={e => this.onDescriptionUpdate(e)} />

                {this.renderToggleActiveButton()}
              </div>
            </div>

            <GroupSelector onGroupSelectedHandler={this.selectedGroupHandler} selectedGroups={this.state.item.selectedGroups} />
            {this.renderFeedOptions()}
            {this.renderBookingOptions()}
            {typeof id !== "undefined" && id !== "" && (
              <DialogContentText className="adminfooter">
                <div>
                  Sist oppdatert: {updated}. Kalender-ID: {id}
                </div>
              </DialogContentText>
            )}
          </div>

          <div className="tabcontainer" id={"tabcontent1"} key="tabcontent1">
            {this.renderCategories(this, id, eventCategories)}
          </div>

          <div className="tabcontainer" id={"tabcontent2"} key="tabcontent2">
            <EventFieldGroup calendarId={id} fieldGroups={fieldGroups} fieldGroupHandler={this.handleFieldGroups} saveFieldGroupsHandler={this.saveField} />
          </div>

          <div className="tabcontainer" id={"tabcontent3"} key="tabcontent3">
            <BookingOptions booking={booking} fieldGroups={fieldGroups} onBookingOptionsChanges={this.bookingOptionsHandler} />
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" className={classes.button} onClick={e => this.cancelDialog(e)}>
            Avbryt
          </Button>
          <Button color="primary" className={classes.button} onClick={e => this.saveItem(e)}>
            Lagre
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
}

EditCalendarDialog.propTypes = {
  item: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  updateItemHandler: PropTypes.func.isRequired
};

export default withStyles(styles)(EditCalendarDialog);
