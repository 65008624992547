export default class Shim {
    constructor() {
        if (!Array.prototype.find) {
            //
            // Shim used by browsers that does not support Array.find().
            //
            Array.prototype.find = function(fn) {
                for (var n = 0; n < this.length; n++) {
                    var r = fn(this[n]);
                    if (r === true) {
                        return this[n];
                    }
                }
                
                return undefined;
            };
        }
    
        if (!Array.prototype.findIndex) {
            //
            // Shim used by browser that does not support Array.findIndex().
            //
            Array.prototype.findIndex = function(fn) {
                if (this.length === 0)        
                    return -1;
    
                for (var n = 0; n < this.length; n++) {
                    var r = fn(this[n]);
                    if (r === true) {
                        return n;
                    }
                }
    
                return -1;
            };
        }
    }
}