import React from "react";

import PropTypes from "prop-types";

import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import IconAdd from "@material-ui/icons/AddCircle";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import AdminUtility from "../../../../utility";
import EditFieldDialog from "../../editfielddialog";

import { withStyles } from "@material-ui/core";

const styles = theme => ({
  root: {
    color: theme.palette.text.primary
  }
});

let selectedField = {};

/**
 * Component used to render the table that lists
 * the fields available for a calendar.
 */
class FieldsTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDeleteDialog: false,
      showEditFieldDialog: false,
      isFetching: true,
      calendarId: "",
      fieldGroupId: "",
      fieldGroupIx: "",
      fields: [],
      fieldGroups: []
    };
  }

  componentDidMount() {
    let fieldGroupId = this.props.fieldGroupId,
      fieldGroupIx = this.props.fieldGroupIx,
      fieldGroups = this.props.fieldGroups,
      calendarId = this.props.calendarId;

    this.setState({
      fieldGroupId: fieldGroupId,
      fieldGroupIx: fieldGroupIx,
      calendarId: calendarId,
      fieldGroups: fieldGroups
    });
  }

  openDeleteDialog = fld => {
    selectedField = fld;
    this.setState({ showDeleteDialog: true });
  };

  cancelDeleteDialog = () => {
    selectedField = {};
    this.setState({ showDeleteDialog: false });
  };

  openFieldDialog = data => {
    if (typeof data.id === "undefined") {
      // No field has been selected, construct a new one!
      selectedField = {
        calendar_id: this.state.calendarId,
        fieldgroup_id: this.state.fieldGroupId,
        fieldgroup_ix: this.state.fieldGroupIx
      };
    } else {
      // A field has been selected for editing.
      data.input_type = AdminUtility.inputTypeToHr(data.input_type);
      data.field_type = AdminUtility.fieldTypeToHr(data.field_type);
      selectedField = data;
    }

    this.setState({
      showEditFieldDialog: true
    });
  };

  closeFieldDialog = () => {
    this.setState({ showEditFieldDialog: false });
  };

  saveField = field => {
    // Create the mapping between the current fieldGroup and field.
    if (this.state.fieldGroupId !== "" && this.state.fieldGroupId !== null) {
      field.field_group_id = this.state.fieldGroupId;
    } else {
      // Index is used on fieldgroups that has not been saved yet.
      // (Newly created)
      field.ix = this.state.fieldGroupIx;
    }

    // Keep the state updated.
    let existingFieldIx = null,
      existingFields = this.state.fields,
      fieldId = field.id;

    if (fieldId !== "") {
      existingFieldIx = existingFields.findIndex(f => {
        return f.id === fieldId;
      });

      existingFields[existingFieldIx] = field;
    } else {
      existingFields.push(field);
    }

    this.setState({ fields: existingFields });

    // Pass on to the saveFieldHandler().
    this.props.saveFieldHandler(field);
    this.render();
  };

  deleteField = () => {
    if (selectedField === {}) {
      this.cancelDeleteDialog();
      return;
    }

    let fieldId = selectedField.id;

    let fieldGroups = this.state.fieldGroups;
    fieldGroups.map(fg => {
      if (fg.fields && fg.fields.length > 0) {
        fg.fields = fg.fields.filter(f => {
          if (f.id === fieldId) {
            f.deleted = true;
          }
          return f;
        });
      }
      return fieldGroups;
    });

    this.setState({
      fieldGroups: fieldGroups,
      showDeleteDialog: false
    });
  };

  _getGroupFields() {
    let fieldGroups = this.state.fieldGroups,
      fieldGroupId = this.state.fieldGroupId,
      fieldGroupIx = this.state.fieldGroupIx,
      fieldGroup = null,
      fields = null;

    if (this.state.fieldGroupId !== "" && this.state.fieldGroupId !== null) {
      fieldGroup = fieldGroups.find(fg => {
        return fg.id === fieldGroupId;
      });
    } else {
      fieldGroup = fieldGroups[fieldGroupIx];
    }

    fields = fieldGroup.fields;

    return fields;
  }

  render() {
    const { classes } = this.props;
    let fields = this.state.fieldGroups;

    if (this.state.fieldGroups && this.state.fieldGroups.length > 0) {
      fields = this._getGroupFields();
    }

    if (typeof fields === "undefined" || fields === null) {
      fields = [];
    }

    return (
      <div>
        <Table>
          <TableHead>
            <TableRow key="tr-1">
              <TableCell key="tc-1">Feltnavn</TableCell>
              <TableCell key="tc-2">Datatype</TableCell>
              <TableCell key="tc-3" numeric="true">
                <IconButton
                  color="primary"
                  onClick={this.openFieldDialog}
                  className={classes.button}
                >
                  <IconAdd />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((el, i) => {
              return (
                <TableRow hover>
                  <TableCell key={"tc-1" + i}>{el.field_label}</TableCell>
                  <TableCell>
                    {AdminUtility.inputTypeToHr(el.input_type) +
                      "/" +
                      AdminUtility.fieldTypeToHr(el.field_type)}
                  </TableCell>
                  <TableCell numeric="true">
                    {!el.deleted &&
                      el.id !== "" && (
                        <IconButton className={classes.button} color="primary">
                          <EditIcon
                            onClick={this.openFieldDialog.bind(this, el)}
                          />
                        </IconButton>
                      )}
                    {!el.deleted && (
                      <IconButton
                        className={classes.button}
                        onClick={this.openDeleteDialog.bind(this, el)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                    {el.deleted && "Merket for sletting"}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        <Dialog open={this.state.showDeleteDialog}>
          <DialogTitle>Bekreft sletting</DialogTitle>
          <DialogContent>Er du sikker?</DialogContent>
          <DialogActions>
            <Button className={classes.icon} onClick={this.cancelDeleteDialog}>
              Cancel
            </Button>
            <Button className={classes.icon} onClick={this.deleteField}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>

        {this.state.showEditFieldDialog && (
          <EditFieldDialog
            open={this.state.showEditFieldDialog}
            item={selectedField}
            saveFieldHandler={e => this.saveField(e)}
            cancelDialogHandler={e => this.closeFieldDialog(e)}
          />
        )}
      </div>
    );
  }
}

FieldsTable.propTypes = {
  fieldGroupId: PropTypes.string.isRequired,
  openEditFieldDialogHandler: PropTypes.func.isRequired
};

export default withStyles(styles)(FieldsTable);
