import React from "react";

import Utility from "../user/utility.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import moment from "moment";
import "moment/locale/nb";
moment.locale("nb");

const styles = theme => ({
  cell: {
    padding: 3
  },
  link: {
    color: "#f50057 !important"
  }
});

/**
 * Component used to display the action log
 * for a calendar event.
 */
class ActionLogViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      entries: [],
      confirmationEntry: [],
      showConfirmationDetails: false
    };
  }

  componentDidMount() {
    let url = "event/logentries/" + this.props.objectID;
    Utility.apiRequest(url, "GET", data => {
      data = this._mergeConfirmationData(data);
      this.setState({ entries: data });
    });
  }

  render() {
    const { classes } = this.props;
    return <div>{this.state && this.state.entries && this.state.entries.length > 0 && this._renderEntryTable(this.state.entries, classes)}</div>;
  }

  /**
   * Renders the table containing the log entries.
   * @param {Array} entries
   * @param {object} classes
   */
  _renderEntryTable(entries, classes) {
    return (
      <React.Fragment>
        <table className="list">
          <thead>
            <tr key={Math.random()}>
              <td className={classes.cell} key={Math.random()}>
                Dato
              </td>
              <td className={classes.cell} key={Math.random()}>
                Hendelse
              </td>
              <td className={classes.cell} key={Math.random()}>
                BrukerID
              </td>
            </tr>
          </thead>
          <tbody>
            {entries.map(entry => {
              return this._renderEntry(entry, classes);
            })}
          </tbody>
        </table>
        {this._renderConfirmationDetailsDialog()}
      </React.Fragment>
    );
  }

  /**
   * Renders a single entry as a table row.
   */
  _renderEntry(entry, classes) {
    return (
      <tr key={Math.random()}>
        <td className={classes.cell} key={Math.random()}>
          {moment(entry.created, "DD.MM.YYYY HH:mm:ss")
            .add(2, "hours")
            .format("DD.MM.YYYY HH:ss:ss")}
        </td>
        <td className={classes.cell} key={Math.random()}>
          {(entry.action_type === 600 && (
            <a href="#" onClick={e => this._showConfirmationDetails(entry, e)} className={classes.link}>
              {Utility.actionTypeToHr(entry.action_type)}
            </a>
          )) ||
            Utility.actionTypeToHr(entry.action_type)}
        </td>
        <td className={classes.cell} key={Math.random()}>
          {entry.user_id}
        </td>
      </tr>
    );
  }

  _renderConfirmationDetailsDialog() {
    const { classes } = this.props;
    let content = this.state.confirmationEntry;
    return (
      <Dialog open={this.state.showConfirmationDetails} onCancel={this._closeConfirmationDetails} disableBackdropClick aria-labelledby="deleteitem-dialog-title">
        <DialogTitle id="deleteitem-dialog-title">Bekreftelse</DialogTitle>
        <DialogContent>
          <p>
            <strong>Mottaker: </strong>
            <br />
            {content.receiver}
          </p>
          <p>
            <strong>Emnefelt: </strong>
            <br />
            {content.subject}
          </p>
          <p>
            <strong>Innhold:</strong>
            <br />
            <span
              style={{ width: "800px" }}
              dangerouslySetInnerHTML={{
                __html: Utility.lineBreakToBr(content.body)
              }}
            />
          </p>
        </DialogContent>
        <DialogActions>
          <Button color="primary" className={classes.button} onClick={e => this._closeConfirmationDetails(e)}>
            Lukk
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  _showConfirmationDetails(entry, event) {
    this.setState({ confirmationEntry: entry.content });
    this.setState({ showConfirmationDetails: true });
  }

  _closeConfirmationDetails(event) {
    this.setState({ showConfirmationDetails: false });
  }

  /**
   * Merges confirmations - if they exist
   */
  _mergeConfirmationData(data) {
    if (typeof this.props.confirmationList === "undefined") {
      return data;
    }

    // Get confirmation values and store them into data object
    let formattedItem = [];
    this.props.confirmationList.map((confirmationItem, j) => {
      formattedItem = {
        created: moment(confirmationItem.created, "DD.MM.YYYY HH:mm:ss")
          .subtract(2, "hours")
          .format("DD.MM.YYYY HH:mm:ss"),
        action_type: 600,
        user_id: confirmationItem.user_id,
        content: {
          subject: confirmationItem.subject,
          body: confirmationItem.body,
          receiver: confirmationItem.receiver
        }
      };
      data.push(formattedItem);
      return formattedItem;
    });

    // Sort by date
    data.sort(function(a, b) {
      a = new Date(moment(a.created, "DD.MM.YYYY HH:mm:ss"));
      b = new Date(moment(b.created, "DD.MM.YYYY HH:mm:ss"));
      return a > b ? -1 : a < b ? 1 : 0;
    });

    return data;
  }
}

ActionLogViewer.propTypes = {
  objectID: PropTypes.string.isRequired,
  confirmationList: PropTypes.object
};

export default withStyles(styles)(ActionLogViewer);
