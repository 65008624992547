import React from "react";

import PropTypes from "prop-types";

import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";

import ActiveIcon from "@material-ui/icons/EventAvailable";
import InactiveIcon from "@material-ui/icons/EventBusy";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import EmbedIcon from "@material-ui/icons/Code";
import IconButton from "@material-ui/core/IconButton";
import ExitToAppIcon from "@material-ui/icons/PlayArrow";

import EditCalendarDialog from "../dialogs/editcalendardialog";
import DeleteItemDialog from "../dialogs/deleteitem";
import EmbedDialog from "../dialogs/embeddialog";

import { withStyles } from "@material-ui/core";

import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";

import AdminUtility from "../../utility";

const styles = theme => ({
  card: {
    marginRight: 10,
    marginBottom: 10,
    maxWidth: 500,
    minWidth: 400,
    backgroundColor: "#fafafa",
    display: "flex",
    flexDirection: "column"
  },
  cell: {
    display: "table-cell"
  },
  cardheader: {
    backgroundColor: "#eee"
  },
  cardbody: {
    paddingBottom: "0",
    display: "flex",
    flex: "1 0 auto"
  },
  cardactions: {
    display: "flex",
    alignItems: "flex-end"
  }
});

/**
 * Component used to render the calendar card displayed in admin.
 */
class CalendarListItem extends React.Component {
  constructor(props) {
    super();

    this.state = {
      showEditDialog: false,
      showDeleteDialog: false,
      itemId: null
    };
  }

  /**
   * Displays the edit dialog.
   */
  showEditDialog = () => {
    this.setState({ showEditDialog: true });
  };

  /**
   * Cancels the edit dialog.
   */
  cancelEditDialog = () => {
    this.setState({ showEditDialog: false });
  };

  /**
   * Displays the delete dialog.
   */
  showDeleteDialog = () => {
    this.setState({ showDeleteDialog: true });
  };

  /**
   * Displays the embed dialog.
   */
  showEmbedDialog = () => {
    this.setState({ showEmbedDialog: true });
  };

  /**
   * Cancels the delete dialog.
   */
  cancelDeleteDialog = () => {
    this.setState({ showDeleteDialog: false });
  };

  /**
   * Cancels the embed dialog.
   */
  cancelEmbedDialog = () => {
    this.setState({ showEmbedDialog: false });
  };

  /**
   * Exits admin, displaying the chosen calendar.
   */
  exitToCalendar = id => {
    let cookie = AdminUtility.getUserCookie();
    if (cookie === null) {
      console.error("User session failed");
      window.location.reload(true);
    } else {
      window.location.replace("/calendar/" + id + "?u=" + cookie.uid);
    }
  };

  /**
   * Renders the calendar cards.
   */
  render() {
    const { classes, data } = this.props;
    const { id, title, description, updated } = data;

    let activeStateColor = "#FF4136";
    let activeStateName = "Ikke publisert";
    let activeIcon = <InactiveIcon />;
    if (this.props.data.active) {
      activeStateColor = "#3f51b5";
      activeStateName = "Publisert";
      activeIcon = <ActiveIcon />;
    }

    return (
      <Card className={classes.card}>
        <CardHeader
          className={classes.cardheader}
          avatar={
            <Tooltip title={activeStateName}>
              <Avatar
                aria-label="Recipe"
                style={{ backgroundColor: activeStateColor }}
              >
                {activeIcon}
              </Avatar>
            </Tooltip>
          }
          title={title}
          subheader={"Oppdatert " + updated}
        />
        <CardContent className={classes.cardbody}>
          <Typography align="left">{description}</Typography>
        </CardContent>
        <CardActions className={classes.cardactions}>
          <Tooltip title="Vis kalender">
            <IconButton onClick={this.exitToCalendar.bind(this, id)}>
              <ExitToAppIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Rediger kalender">
            <IconButton onClick={e => this.showEditDialog(e)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <EditCalendarDialog
            open={this.state.showEditDialog}
            item={this.props.data}
            onCancel={this.cancelEditDialog}
            updateItemHandler={e => this.props.onUpdateItemHandler(e)}
          />
          <Tooltip title="Slett kalender">
            <IconButton onClick={e => this.showDeleteDialog(e)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <DeleteItemDialog
            open={this.state.showDeleteDialog}
            itemId={id}
            onCancel={this.cancelDeleteDialog}
            deleteItemHandler={e => this.props.onDeleteItemHandler(e)}
          />
          <Tooltip title="Embed kalender">
            <IconButton onClick={e => this.showEmbedDialog(e)}>
              <EmbedIcon />
            </IconButton>
          </Tooltip>
          <EmbedDialog
            open={this.state.showEmbedDialog}
            calendarId={id}
            onCancel={this.cancelEmbedDialog}
          />
        </CardActions>
      </Card>
    );
  }
}

CalendarListItem.propTypes = {
  classes: PropTypes.object.isRequired,
  onDeleteItemHandler: PropTypes.func.isRequired, // Handles item deletion Calendar.deleteItemHandler()
  onUpdateItemHandler: PropTypes.func.isRequired // Handles item update Calendar.updateItemHandler()
};

export default withStyles(styles)(CalendarListItem);
